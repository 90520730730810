/*Vertical Navigation*/
#main-sidebar.sb-compressed { width: 55px; }
#main-sidebar.sb-compressed .cl-vnavigation li { position:relative; }
#main-sidebar.sb-compressed .cl-vnavigation>.parent>a:before { display: none; }
#main-sidebar.sb-compressed .cl-sidebar .cl-vnavigation { display:block !important; }
#main-sidebar.sb-compressed .cl-vnavigation li ul:not(.submenu) { display: none !important; }
#main-sidebar.sb-compressed .cl-vnavigation li.dropdown-submenu ul.submenu.open { left: 74.85px; }

.sidebar-toggle {
    text-align:center;
    font-size:0.6em;
    color: #aaa;
    width: 14px;
    height:100%;
    position:absolute;
    border-right: 1px dotted #bbb;
    transition: background 0.1s;
    padding-left: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}
.sidebar-toggle:hover {
    background:#ccc;
    color: #888;
    cursor: pointer;
}
.cl-sidebar {
    background: @sidebar-color;
    box-shadow:1px 0 3px #000;
    position:absolute;
	width:250px;
    z-index:2;
    transition: width 0.5s;
}
.sb-arrows {
    position: relative;
    top: 40vh;
    transition: top 0.2s;
}
.sb-arrows .bullets {
    line-height:0.7em;
    font-size:0.8em;
}

/* TOOLTIPS */

#main-sidebar .tooltip {
    display: none !important;
    font-family: Campton;
    position: fixed;
    text-transform: uppercase;
    z-index: 10;
    top: -10px;
}
#main-sidebar.sb-compressed .tooltip { display: block !important; }

.cl-sidebar .cl-toggle{
	display:none;
	padding: 7px 8px;
	text-align:center;
	cursor:pointer;
    position: absolute;
    right: 15px;
    top: 14px;
    background: rgba(0,0,0,0.15);
    border-radius: 3px;
}

.cl-sidebar .cl-vnavigation.nav-expand li:not(.dropdown-submenu) {
    max-height: 100px;
}

.cl-sidebar .cl-vnavigation.nav-expand li.dropdown-submenu {
    max-height: 500px;
}
.cl-sidebar .cl-vnavigation.nav-expand li a { opacity: 1; }
.cl-sidebar .cl-toggle i{
    color: #fff;
	font-size:21px;
}

#cl-wrapper .cl-sidebar .side-user .info p{
  color:#FFF;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 2px;
}
.progress.progress-user{
height: 4px;
border-radius: 0;
}
.progress.progress-user > .progress-bar{
background-color: @primary-color;
}
.cl-vnavigation{
	margin:20px 0 0 0;
	padding:0;
}
.cl-vnavigation>.parent>a:before{
  display: inline-block;
  content: "";
  font-family: 'FontAwesome';
  float: right;
  -webkit-transition: rotate 250ms ease;
  -moz-transition: rotate 250ms ease;
  -o-transition: rotate 250ms ease;
  transition: transform 250ms ease;
}
.cl-vnavigation>.parent.open>a:before {
  transform: rotate(90deg);
}
.cl-vnavigation ul.submenu {
    padding: 0;
    z-index: 10;
}
.cl-vnavigation ul.submenu li a {
    padding: 12px;
}
.cl-vnavigation ul.submenu li:first-child a { padding-top: 15px; }
.cl-vnavigation ul.submenu li:last-child a { padding-bottom: 20px; }
.cl-vnavigation li a {
	color: white;
	display:block;
    font-family: Campton, sans-serif;
    letter-spacing: .08em;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-transition: color 250ms ease;
    -moz-transition: color 250ms ease;
    transition: color 250ms ease;
}
.cl-vnavigation > li > a{
	border-top: 1px solid rgba(255,255,255,0.04);
    display: flex;
    align-items: center;
	font-size: 14px;
    font-weight: 400;
	padding: 14px 25px;
}
.cl-vnavigation li:first-child a{
    border-top: none;
}
.cl-vnavigation li a:hover{
	color: rgba(255, 255, 255, 1);
}
.cl-vnavigation i.fa {
    color:  #ccc;
    font-size: 1.4em;
    margin-right: 15px;
    transition: margin 0.5s;
}
.cl-vnavigation li a:hover i.fa {
    color: @abet-orange + 15%;
}
.parent .sub-menu{padding:10px 0;}
.sub-menu li a{border:0;}
.sub-menu li a:hover {background-color: rgba(0, 0, 0, 0.1);}

.cl-vnavigation li a:hover{
	background-color: @sidebar-color - 5%;
}

/*Collapsed Sidebar*/

.sb-collapsed .cl-sidebar{
  width:70px;
}
.sb-collapsed .sidebar-logo .logo a{
  content:" ";
  display:block;
}
.sb-collapsed #pcont{
  margin-left:55px;
}

.sb-collapsed	.cl-vnavigation li {position:relative;}
.sb-collapsed .cl-vnavigation .parent a{background-image:none;}
.sb-collapsed .cl-vnavigation>.parent>a:before{display: none;}
.sb-collapsed .cl-vnavigation li a i{margin:0;font-size:17px;}
.sb-collapsed .cl-vnavigation li a span{opacity:0;width:0;height:0;}


#cl-wrapper.animate-collapse .cl-sidebar{
   -webkit-transition: width 300ms linear;  /* Chrome 1-25, Safari 3.2+ */
     -moz-transition: width 300ms linear;  /* Firefox 4-15 */
       -o-transition: width 300ms linear;  /* Opera 10.50–12.00 */
          transition: width 500ms linear;
}
#cl-wrapper.animate-collapse #pcont{
  transition: margin 500ms ease;
}
#cl-wrapper.animate-collapse .cl-vnavigation li a {}
#cl-wrapper.animate-collapse .cl-vnavigation li a span{
  -webkit-transition:opacity 500ms linear;
     -moz-transition:opacity 500ms linear;
       -o-transition:opacity 500ms linear;
          transition:opacity 500ms linear;
}

#cl-wrapper.animate-collapse .cl-vnavigation li a i{
-webkit-transition:margin 500ms linear, font-size 500ms linear;
-moz-transition:margin 500ms linear, font-size 500ms linear;
-o-transition:margin 500ms linear, font-size 500ms linear;
  transition:margin 500ms linear, font-size 500ms linear;
}

.cl-sidebar .collapse-button{
  background: @sidebar-color;
  position:fixed;
  width:280px;
  bottom:0;
  border-top: 1px solid rgba(255,255,255,0.04);
}

.cl-sidebar .collapse-button .search{
  width:219px;
  display:inline-block;
  margin-right:3px; 
  border-radius:2px;
  height:33px;
  color: rgba(255,255,255,0.7);
  background: none repeat scroll 0 0 #181A21;
  font-size:11px;
  border: 1px solid rgba(0, 0, 0, 0.06);}

.cl-sidebar .collapse-button button{
  margin:0 !important;
  background:transparent;
  box-shadow: none;
  border: 1px solid rgba(255,255,255,0.07);
  border-radius:2px;
}

.cl-sidebar .collapse-button button:hover{
  background: @sidebar-color - 10%;
}

#cl-wrapper.fixed-menu .menu-space{
  position: fixed; 
  width: 216px;
  background:#272930;
}
#cl-wrapper.fixed-menu .menu-space .content{outline:none;}


#cl-wrapper.sb-collapsed.fixed-menu .menu-space{
  width: 70px;
}

#cl-wrapper .cl-sidebar .side-user{
  padding: 25px 20px;
}

.sb-collapsed .cl-sidebar .side-user{
  display:none;
}


#cl-wrapper.animate-collapse .cl-sidebar .collapse-button{
   -webkit-transition: width 300ms linear;  /* Chrome 1-25, Safari 3.2+ */
   -moz-transition: width 300ms linear;  /* Firefox 4-15 */
     -o-transition: width 300ms linear;  /* Opera 10.50–12.00 */
        transition: width 500ms linear;
}

.sb-collapsed .cl-sidebar .collapse-button .search{
  display:none;
}

.sb-collapsed .cl-sidebar .collapse-button{
  width:70px;
  text-align: center;
}

.sb-collapsed .cl-vnavigation li.open ul{display: none !important;}


#sub-menu-nav ul{ 
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.22);
    color:#333;
	background:#FFF;
	padding: 5px 0;
	min-width:190px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);    
    border:1px solid rgba(0, 0, 0, 0.15);
    border-top:0;
    border-radius: 3px;
	}
  
  #sub-menu-nav ul li:first-child{padding-top:0;}
  #sub-menu-nav ul li:last-child{padding-bottom:0;}

  #sub-menu-nav ul li.dropdown-header i {
    text-align: center;
    width: 15px;
  }
  
  #sub-menu-nav ul li.dropdown-header{
    padding:1px 11px;
    padding-bottom:6px;
  }

  #sub-menu-nav  ul{padding:5px 0;}
  #sub-menu-nav  ul li{list-style:none;}
  #sub-menu-nav  ul li a{
    margin-right: -1px;
    display:block;
    font-size:11px;
    border-bottom: 0;
    color: #333333;
    text-shadow:none;
	text-align:left;
    background: transparent;
    padding: 6px 20px 6px 31px
  }
	
  #sub-menu-nav ul li a:hover{
    background-color:@primary-color;
    color:#FFF;
  }
  
  #sub-menu-nav  ul:after{
    background: url(../images/menu-tail-left.png) no-repeat right 10px;
    top: 0px;
    content: "";
    display: inline-block;
    left: -9px;
    height:43px;
    width:10px;
    position: absolute;
  }
  
	/*.sb-collapsed .cl-vnavigation li:hover ul{display: block !important;}
	.sb-collapsed .cl-vnavigation li.ocult ul{display:none !important;}
	
*/

/*End Collapsed*/

.cl-vnavigation li a span{
  display:inline-block;
  flex-grow: 1;
  font-size: 0.9em;
  max-height: 50px;
  /*max-width: 100px;*/
  transition: opacity 0.2s, max-height 0.2s, max-width 0.2s;
  white-space: pre-wrap;
}

.cl-vnavigation li ul{
    background-color: @sidebar-color - 10%;
	margin:0;
	padding:0;
    padding-top:4px;
}

.cl-vnavigation li ul:not(.open) {
	display:none;
}

.cl-vnavigation li.dropdown-submenu ul.submenu.open {
    display: block;
    position: absolute;
    top: 0;
    left: 251px;
    list-style: none;
}

.cl-vnavigation .parent ul li{
	list-style:none;
}

.cl-vnavigation .parent ul li.dropdown-header{
  display:none;
}

.cl-vnavigation .parent ul li a{
	font-size:12px;
	background-image: none;
	padding: 7px 18px 7px 42px;
}

.cl-vnavigation .parent ul li.active a{
  color: rgba(255,255,255,1);
	background-color: rgba(0,0,0,0.2);
	border-left: 2px solid @primary-color;
}

.cl-vnavigation .parent ul li a:hover{
	background-color: rgba(0,0,0,0.2);
}

.cl-vnavigation .parent ul li a:first-child{
	border-top: 0;
}

.cl-vnavigation .active > a{
    background:#2A2C34;
   	border-left:2px solid @primary-color;
}

.cl-vnavigation .selected:hover a{background-color: #2494F2;}
.cl-vnavigation .selected:hover ul a{background-color: #1A1C20;}


body{
  height:100%;
}
/*
.cl-sidebar{
  min-height:100%;
}*/

 body.animated #cl-wrapper{
  opacity:0;
  margin-left:-20px;
  transition: margin-left 600ms ease, opacity 600ms;
 }
#cl-wrapper{
  display:table;
  width:100%;
  position:absolute;
  height:100%;
}

.cl-sidebar{
  display:table-cell;
  position:relative;
  vertical-align:top;
  padding-bottom: 200px;
}

#pcont{
  display:table-cell;
  vertical-align:top;
}