
/*Login*/
#cl-wrapper.login-container, #cl-wrapper.error-container{
	margin:0;
}

.login-container .middle-login{
	width:430px;
	left: 50%;
	top: 45%;
	position:absolute;
	margin-top: -170px;
	margin-left: -215px;
}

.login-container .block-flat{
	padding:0;
	border:0;
	overflow:hidden;
	border-radius:2px;
	-webkit-border-radius:2px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
	margin-bottom:20px;
}

.login-container .block-flat .header{
	padding: 9px 20px;
	background:@abet-gray;
}

.login-container .block-flat .header h3{
    background: url(../images/ABET_WHITETXT_SM.png) top left no-repeat;
    background-size: 36px 36px;
    color:#FFF;
    line-height:35px;
    margin:0 15px;
    min-height: 40px;
    position: relative;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
}

.login-container .header .logo-img {
    left: 50%;
    margin-left: -63px;
    margin-top: -13px;
    position: absolute;
    top: 50%;
}
.login-container .block-flat .content{
	padding:30px;
	padding-bottom:5px;
}

.login-container .block-flat .content .form-control{padding:10px 8px;height:auto;}
.login-container .block-flat .content .form-group {
	margin-bottom:10px;
}

.login-container .block-flat .content .title{margin-top:0;margin-bottom:20px;}

.login-container .block-flat .foot{
	text-align:right;
	padding:0 30px 15px;
}

.login-container .block-flat .foot .btn{min-width:70px;}
.login-container .block-flat  .input-group-addon{background:#FAFAFA;}

.login-container  .out-links{
	padding-right: 3px;
}

.login-container  .out-links a{  color: #C9D4F6; text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);}

