/*Labels*/
.label {
	border-radius:0;
	-webkit-border-radius:0;
	 font-weight: normal;
	font-size: 95%;
}
.label-default {background-color:#a1a1a1;}
.label i{font-size:100% !important;color: inherit !important;width: auto !important;min-width: 11px;}
.label-success {background-color: @dark-green;}
.label-primary {background-color: @primary-color}
.label-warning {background-color: @yellow;}
.label-danger {background-color: @red;}
.label-info {background-color: @blue;}

.label-primary[href]:hover, .label-primary[href]:focus{
background-color: @primary-color - 10%;
}
.label-success[href]:hover, .label-success[href]:focus{
background-color: @dark-green - 10%;
}
.label-info[href]:hover, .label-info[href]:focus{
background-color: @blue - 10%;
}
.label-warning[href]:hover, .label-warning[href]:focus{
background-color: @yellow - 10%;
}
.label-danger[href]:hover, .label-danger[href]:focus{
background-color: @red - 10%;
}

/*Badge*/
.badge {
	font-weight: normal;
	font-size: 95%;
	padding:4px 6px;
	background-color:#a1a1a1;
}

.badge.above-title {
    margin-bottom: 8px;
}

.header-badge {
    font-family: Campton, sans-serif;
    white-space: nowrap;
    min-width: 5rem;
    max-width: 7rem;
    border-radius: 10px;
    margin: 0 10px;
    flex: none;
    text-align: center;
    align-self: center;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 800;
    letter-spacing: .06rem;
    padding: 0.3rem 1rem;
    text-align: center;
    text-transform: uppercase;
}

.badge-primary{background-color: @primary-color !important;}
.badge-success{background-color:  @dark-green !important;}
.badge-info{background-color: @blue !important;}
.badge-warning{background-color: @yellow !important;}
.badge-danger{background-color: @red !important;}
.badge-dev {
    background-color: @abet-green;
    color: #fff;
}
.badge-stage {
    background-color: @abet-orange;
    color: #fff;
}
