/*404 Page*/
.page-error{
	margin-top:80px;
	margin-bottom:40px;
}

.page-error .number{
	color:#FFF;
	font-size:150px;
	font-family: Arial;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
}

.page-error .description{
	color:#FFF;
	font-size:40px;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
}

.page-error h3{
	color:#FFF;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
}

.error-container .copy, .error-container .copy a{
	color: #C9D4F6;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

body.texture{
	background: @dark;
}