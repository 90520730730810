/*Select 2*/
.select2-container .select2-choice {
	border: 1px solid #ccc;
	background:#fff;
	padding: 4px 8px;
	height:auto;
	border-radius:1px;
	-webkit-border-radius:1px;
}
.select2-drop{
  box-shadow: none;
}
/*box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.18);*/
.select2-dropdown-open.select2-drop-above .select2-choice, .select2-dropdown-open.select2-drop-above .select2-choices {
	background:#fff;
  border-color: rgba(0, 0, 0, 0) @primary-color @primary-color;
}
.select2-container .select2-container-multi .select2 .select2-dropdown-open{
  box-shadow: none;
}
.select2-container-multi.select2-container-active .select2-choices{
  box-shadow: none;
  border: 1px solid @primary-color;
}
.select2-drop-active{
  border-color: -moz-use-text-color @primary-color @primary-color;
}
.select2-drop.select2-drop-above.select2-drop-active{
  border-top: 1px solid @primary-color;
}
.select2-drop.select2-drop-above {
 box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
}
.select2-container .select2-choice abbr{
  right: 32px;
  top: 10px;
}
.select2-container-active .select2-choice, .select2-container-active .select2-choices{
	box-shadow:none;
  border-color: @primary-color;
}

.select2-container .select2-choice .select2-arrow {
	width:30px;
	background-color: #FFFFFF;
  background-image: none;
}
.select2-results .select2-highlighted{
  background: none repeat scroll 0 0 @primary-color;
}
.select2-container .select2-choice .select2-arrow b {
    background-position: 7px 4px;	
}

.select2-dropdown-open .select2-choice .select2-arrow b {
	background-position: -13px 3px;
}

.select2-container.select2-drop-above .select2-choice {
	border-radius: 0 0 2px 2px;
	-webkit-border-radius: 0 0 2px 2px;
}

/*Tags*/
.select2-container-multi .select2-choices .select2-search-choice {
	border: none;
  border-radius: 0;
	background-color: @primary-color;
  background-image: none;
  color: #fff;
  box-shadow: none;
	padding: 3px 5px 5px 18px;
}
.select2-container-multi .select2-choices{
	padding:5px;
	background: #fff;
	box-shadow: none;
}
