
/*Profile menu*/
.profile_menu{
	margin-left:10px;
	cursor:pointer;
}

.not-nav{
	margin:0 !important;
}

.user-nav{
	margin:0;
	margin-right: -15px;
}

.profile_menu .dropdown-toggle{padding: 17px 10px;}

.profile_menu img{ 
	margin-right:5px;
	border-radius: 2px;
	-webkit-border-radius: 2px;
}
/*END - Profile menu*/
