/*Tiles*/
.fd-tile{
  background-color: #fff;
  color: #555;
  margin-bottom: 15px;
}
.fd-tile .row{
  margin: 0;
}

.fd-tile.weather{
  text-align:center;
  padding-top:20px;
}
.weather h2 {
  margin-top:10px;
  margin-bottom: 0;
  color: #fff;
  font-weight: 500;
}
.weather h1{
  font-size: 50px;
  margin-top:0;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 600;
}
.fd-tile.weather .days{
  background:rgba(255,255,255,0.1);
}.fd-tile.weather .day{
  padding:5px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.fd-tile.weather .day h6{
  color: #fff;
  font-weight: 400;
}
.fd-tile.tile-green{
  background-color: @dark-green;
  color: #fff;
}
.fd-tile.tile-lemon{
  background-color: @dark-lemon;
  color: #fff;
}
.fd-tile.tile-red{
  background-color: @red;
  color: #fff;
}
.fd-tile.tile-blue{
  background-color: @blue;
  color: #fff;
}
.fd-tile.tile-orange{
  background-color: @orange;
  color: #fff;
}
.fd-tile.tile-prusia{
  background-color: @prusia;
  color: #fff;
}.fd-tile.tile-concrete{
  background-color: @concrete;
  color: #fff;
}
.fd-tile.tile-purple{
  background-color: @purple;
  color: #fff;
}
/*clean tiles*/
.fd-tile.clean .icon i{
  color: #e5e5e5;
}
.fd-tile.clean{
  background-color: #fff;
  color: #888;
}

.fd-tile.clean.tile-green .details{
  background-color: @dark-green;
}
.fd-tile.clean.tile-lemon .details{
  background-color: @dark-lemon;
}
.fd-tile.clean.tile-red .details{
  background-color: @red;
}
.fd-tile.clean.tile-blue .details{
  background-color: @blue;
}
.fd-tile.clean.tile-orange .details{
  background-color: @orange;
}
.fd-tile.clean.tile-prusia .details{
  background-color: @prusia;
}
.fd-tile.clean.tile-concrete .details{
  background-color: @concrete;
}
.fd-tile.clean.tile-purple .details{
  background-color: @purple;
}


.fd-tile .header{ border-bottom: 2px solid rgba(255,255,255,0.2);}
.fd-tile .header .ico{
  background: rgba(255, 255, 255, 0.1);
  display: inline-block;
  padding: 11px 18px;
  font-size: 20px;
  text-align: center;
  vertical-align: center;
}
.fd-tile .header .title{ 
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
  top: 7px;}
.fd-tile .content{
  padding: 10px;
}
.fd-tile.header .content{
  padding: 10px 20px;
  background: transparent;
  padding-right: 10px;
  display: inline-block;
  position: relative;
  z-index: 3;
}
.fd-tile.detail .icon {
    display: block;
    float: right;
    height: 80px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-top: 10px;
    position: absolute;
    right: 10px;
    top: 0;
    width: 80px;
}.fd-tile.header .icon img{
  opacity: 0.8;
  display: block;
  float: right;
  height: 80px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-top: 10px;
  position: absolute;
  right: 10px;
  top: 50px;
  width: 80px;

}

.fd-tile.header{
  position:relative;
  overflow: hidden;
}
.fd-tile.detail{
  position:relative;
  overflow: hidden;
}
.fd-tile.detail .content{
  background: transparent;
  padding: 10px 10px 13px;
  display: inline-block;
  position: relative;
  z-index: 3;
}
.fd-tile.detail .icon {
    display: block;
    float: right;
    height: 80px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-top: 10px;
    width: 80px;
}
.fd-tile.detail .icon i{
    color: rgba(0, 0, 0, 0.05);
    font-size: 100px;
    line-height: 65px;
}
.fd-tile.detail .details{
  clear: both;
  display: block;
  padding: 5px 10px;
  color: #fff;
  text-transform: uppercase;
  background-color: rgba(0,0,0,0.1);
}
.fd-tile.detail .details i{
  font-size: 18px;
  color: rgba(255,255,255,0.4);
}

.fd-tile .content p{
  margin-bottom: 0;
  font-weight: 4  00;
  font-size: 14px;
}
.fd-tile .content h1{
  margin: 0 0 0;
  font-weight: 300;
  font-size: 40px;
}


/*Stats*/
.stats_bar{
	text-align:center;
	margin-top:15px;
}

.butpro {
    vertical-align: top;
}

.butstyle {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #f8f8f8;
    background-image: -moz-linear-gradient(center top , #FFFFFF 50%, #f8f8f8 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#f8f8f8)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #FFFFFF, #f8f8f8); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #FFFFFF, #f8f8f8); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #FFFFFF 50%, #f8f8f8 100%);
	
    border-color: #DEDFE0 #C8C8C8 #C8C8C8;
    border-image: none;
    border-right: 1px solid #C8C8C8;
    border-style: solid;
    border-width: 1px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
    color: #333333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans',Verdana,sans-serif;
    font-size: 11px;
	margin-right: 1.8%;
	margin-bottom: 15px;
	width: 14.2%;
    padding: 6px 9px 4px;
    transition: border-color 0.21s ease-out 0s;
}

.butstyle:first-child{margin-left:0;}

.butstyle:hover {
    border-color: #4D90FE;
}

.butpro .sub h2 {
	margin:0 0 5px 0;
    font-size: 11px;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
}

.butpro .sub span {
    color: #434D55;
    display: block;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
	line-height: 26px;
}

.butpro .stat {
    padding: 4px 0;
    text-align: center;
}

.butpro .stat .equal {
    background: url("../images/stat-same.png") no-repeat scroll left center transparent;
    font-size: 12px;
    padding: 0 0 0 19px;
}

.butpro .stat .up {
    background: url("../images/stat-up.png") no-repeat scroll left center transparent;
    font-size: 12px;
    padding: 0 0 0 19px;
}

.butpro .stat .down {
    background: url("../images/stat-down.png") no-repeat scroll left center transparent;
    font-size: 12px;
    padding: 0 0 0 19px;
}

.butpro .sub {
    padding-top: 4px;
}
