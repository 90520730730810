.daterangepicker td.active, .daterangepicker td.active:hover{
  background-color: @primary-color;
  border-color: transparent;
}
.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover{
  background: @dark-green;
  border: 1px solid @dark-green;
}
.daterangepicker .ranges li{
  color: #666;
}