﻿/*body { padding-top: 50px; padding-bottom: 20px; }*/

/* Set padding to keep content from hitting the edges */
.body-content { /*padding-left: 15px; padding-right: 15px;*/ position: relative; width:100%; }

/* Set width on the form input elements since they're 100% wide by default */
/*input,
select,
textarea { max-width: 1000px; }*/

a, a:hover, a i.fa, a:hover i.fa, i, i:hover, button, button:hover {
	-webkit-transition: all 0.30s ease-in-out;
	-moz-transition: all 0.30s ease-in-out;
	-ms-transition: all 0.30s ease-in-out;
	-o-transition: all 0.30s ease-in-out;
	transition: all 0.30s ease-in-out;
}

body.modal-open {
  overflow: inherit;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak { display: none !important; }

.ng-enter, .ng-leave { -webkit-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s; transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s; display: block; width: 1170px; padding-left: 15px; padding-right: 15px; position: absolute; left: 0; right: 0; }
.ng-enter { left: 100%; }
.ng-enter.ng-enter-active { left: 0; }
.ng-leave.ng-leave-active { left: -200%; }
textarea { resize: vertical; }
.view-container {
    border-radius: 25px;
    border: 2px solid #c0c0c0;
    padding: 1%; 
    width: 500px;
    height: 300px;
    display: inline-block;
    margin: 20px;
}

.view-container .header {
    margin-bottom: 20px;
}

.view-container .content {
    border: 2px solid #c0c0c0;
}

.row {
    padding: 5px;
}

.btn-noborder {
    background-color: transparent;
    border:0px;
}
.customLink {
    color: #0094DE;
    cursor:pointer;
}

/* Global Styles*/
.omnisearch span:hover {
    cursor: pointer;
}
#breadcrumbs-container {
    /*
    border-radius: 2px;
    margin: 10px 35px 0;*/
    border-bottom: 1px dotted #bbb;
    font-size: 0.9em;
    margin-left: 14px;
    padding: 5px 10px;
}
ul.breadcrumbs {
    margin: 0;
    margin-left: 10px;
    padding: 0;
}
ul.breadcrumbs li {
    list-style: none;
    display: inline;
}
ul.breadcrumbs li:after {
    content: '/';
    padding-left: 5px;
    margin-right: 5px;
}
ul.breadcrumbs li:last-child:after {
    content: '';
    margin: 0;
}
ul.breadcrumbs a {
    color: #888;
    text-transform: capitalize;
}
ul.breadcrumbs a:hover {
    color: #bbb;
}
ul.breadcrumbs li.active a {
    user-select: none;
    color: #555;
}
.cl-mcont > .row {
    margin-top: 0 !important;
}
.long-form .form-group {
    margin-bottom: 0;
}
.condensed-form .form-group,
.condensed-form .row {
    margin: 0;
}
i.fa.has-tooltip {
    color: #ccc;
    font-size: 1.4em;
    margin-left: 5px;
}
.btn i.fa.has-tooltip {
    margin-left: 0;
}
button i.fa.has-tooltip {
    font-size: 1.1em;
}

.program-links i.fa.has-tooltip,
.program-links btn.add-new-line {
    margin-left: 0 !important;
}
/* General */
.float-right {
    float: right;
}
.float-left {
    float: left;
}
.add-new-line i { font-size:1.4em; }

.inline-link a {
    color: #696767;
}
.inline-link:hover {
    color: black;
    cursor: pointer;
}
.inline-link:hover a {
    color: #f56222;
}
h5 span.destyle-heading {
    text-transform: capitalize;
    font-style: italic;
}
.heading-highlight {
    width: 140px;
    display: inline-block;
}
/*.content td {
    width: 100%;
}*/
.button-container {
    text-align:right; margin-top: 20px;
}
.separator {
    border-top: 1px solid #e5e5e5;
    margin-top: 30px;
    padding-top: 20px;
}
.repeat-container:not(:last-of-type) {
    margin-bottom: 10px !important;
}
.cl-mcont h4 {
    margin-left: 15px;
}
.inline-checkbox {
    display: inline-block;
    padding-right: 10px;
}
ul.semi-style li:not(:only-child) {
    margin-left: 15px;
}
ul.semi-style li:not(:last-child),
ul.no-style li:not(:last-child) {
    margin-bottom: 5px;
}
ul.inline {
    display: inline-block;
}
ul.no-style,
ul.semi-style,
ul.striped,
ul.striped > li {
    margin: 0; padding: 0;
}
ul.no-bullet {
    list-style: none;
}
ul.padded-list li,
ul.contains-divs.semi-padded-list li {
    padding: 5px 10px 10px;
}
ul.no-heading-margin h5 {
    margin: 0;
}
ul li:only-child,
ul.no-style > li,
ul.striped > li {
    list-style: none;
}
ul.striped:not(.contains-divs) > li {
    padding: 5px;
}
ul.striped > li:not(:last-child) {
    margin-bottom: 5px;
}
ul.striped > li:nth-child(2n) {
    background: #f4f4f4;
}
ul.striped h5,
.detail-container.listing h5 {
    color: #696767; letter-spacing: 0.05em;
}
.heading { margin-bottom: 10px; display: block; }
.description { margin: 10px 0; }
#remove-panels .panel,
#remove-panels .panel-body,
#remove-panels .panel-default,
#remove-panels .panel-group,
#remove-panels .panel-heading,
#remove-panels .panel-title {
    background: none;
    border: none;
    border-radius: unset;
    box-shadow: none;
    padding: 0;
    margin: 0;
}
.single-program .two-btns {
    min-width: 100px;
}

/* Edit Pages */

.detail-container .form-group .btn {
    float: right;
}
.detail-container .form-group:hover {
    background: #F0F0F0;
}
.detail-container ul.detail-list {
    padding-left: 0;
    margin-bottom: 0;
}
.detail-container ul.detail-list li {
    list-style: none;
    margin-bottom: 10px;
}
.detail-container ul.addresses li:last-child {
    margin-bottom: 0;
}
.form-control + .form-control {
    margin-top: 10px;
}
.btn-nostyle {
    padding: 0;
    border: none;
    background: none;
    transition: color 0.1s;
}
.btn-nostyle:hover {
    color: #ff6c2c;
}
.btn-inline {
    padding-left: 5px;
}
.btn-notextstyle {
    font-family: Egyptienne, serif;
    text-transform: capitalize;
}
.btn-group ul.dropdown-menu:not(.multiselect-dropdown-menu) {
    /*margin-left: 12px;*/
    margin-top: 5px;
}
.btn-group ul.multiselect-dropdown-menu {
    margin-top: 1px;
    min-width: 100%;
    width: auto;
}
multiselect-dropdown .btn.dropdown-toggle {
    color: #555;
    font-size: 14px;
    letter-spacing: unset;
    text-transform: none;
}

/* MODALS */
.modal-header h4 { font-size: 20px; }
.modal-header h6 { font-size: 13px; letter-spacing: 0.05em; }
.modal-body h4 {
    color: #bbb;
    /*border-bottom: 1px solid #eee;*/
    padding-bottom: 15px;
}
.modal-body h4 span {
    color: #555;
    display: inline-block;
    font-family: Egyptienne, serif;
    font-size: 15px;
    font-style: italic;
    margin-left: 10px;
    text-transform: none;
}
.form-group + h4 { margin-top: 50px; }
.single-address + .single-address,
.single-email + .single-email {
    border: none;
    margin-top: 0;
    padding-top: 0;
}
.single + .single,
.single-address:nth-of-type(n+3),
.add-edit-fields {
    border-top: 1px solid #eee;
    margin-top: 15px;
    padding-top: 15px;
}

.single-phone + .single-phone {
    margin-top: 30px;
    padding-top: 30px;
}

.modal-content h3.popover-title { display: block; }
.popover-wide { width: 200px; }
.address-type {
    font-weight: 800;
    margin: 8px 0;
}
.popover textarea { resize: none; }
.modal-body .add-new {
    margin-top: 30px;
}

.update-modal .username {
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
}

.modal-body .change-history.program-container:not(:last-of-type) {
    margin-bottom: 30px;
}

.modal-body .change-history.program-container .program-details {
    margin-bottom: 15px;
}

/* SEARCH PAGES */

.search-page .header {
    display: flex;
    margin-bottom: 20px;
}

.search-page .header > * {
    flex-grow: 1;
}

.search-page .header > div {
    flex-shrink: 0;
}
.search-page .header > div.text-right {
    flex-shrink: 1;
}
.add-new-container {
    padding: 20px;
    text-align: center;
}
.add-new-heading {
    color: #b5b5b5;
    font-size: 20px;
}
.search-results-container {
    min-height: 80vmin;
    position: relative;
}
.search-info {
    position: absolute;
    height: 80%;
    width: 95%;
    text-align: center;
}
.search-info h1,
h2.no-records {
    color: #E4E4E4;
    cursor: default;
    margin-top: 15% !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}
h2.no-records {
    margin: 30px 0 !important;
    text-align: center;
}


/* REVIEWS */

.review-year-container {
    margin-top: 20px;
}
.review-year-container h4 {
    margin-bottom: 0;
}
.review-year-container .list-container.col-offset-70 {
    margin-top: -5px;
}
.review-year-container.single-review .list-container.col-offset-70 {
    margin-top: -10px;
}
.list-container.review li h5 {
    margin: 0;
}
.list-container.review .review-year h5 {
    margin-top: 6px;
}


/* LISTING */

.search-results ul,
.list-container ul {
    margin: 0;
    padding: 0;
}
.block-flat.review {
    min-height: 400px;
}
.list-container > ul {
    margin-left: -5px;
}
.list-container.column > ul > li {
    margin-left: 0;
}
.search-results li,
.list-container > ul:not(.no-style) > li {
    list-style: none;
    padding: 5px 10px 10px;
}
.search-results ul:not(.no-style):not(.inverse-stripe) li:nth-child(2n+1) {
    background: #f4f4f4;
}
.search-results ul.inverse-stripe:not(.no-style) li:nth-child(2n) {
    background: #f4f4f4;
}

.search-results ul li li {
    background: none !important;
}

.list-container > ul:not(.no-style) > li {
    margin-left: 10px;
}
.list-container:not(.column):not(.statement) > ul > li:not(.visit-team-info):hover,
.list-container.column > ul > li.inline-link:hover {
    cursor: pointer;
    border-left: 10px solid #ccc;
    margin-left: 0;
    position: relative;
}
.list-container:not(.flat):not(.column):not(.statement) > ul > li:not(.visit-team-info):hover,
.list-container:not(.flat).column > ul > li.inline-link:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.list-container.column > ul > li.inline-link:hover {
    margin-left: -10px;
}
.list-container li.active,
.list-container li.active:hover {
    border-left: 10px solid #FF6C2C;
    margin-left: 0;
    cursor: default;
    position: relative;
    z-index: 10;
}
.list-container:not(.flat) li.active,
.list-container:not(.flat) li.active:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.list-container.flat li.active {
    background: white;
}
.list-container.flat li h5 {
    margin: 5px 0;
}
.list-container li a,
.list-container li h5 {
    color: #696767;
    letter-spacing: 0.05em;
}
.list-container li.active h5 a {
    color: #FF6C2C;
}
.visit-team-info {
    border-top: 1px solid #e5e5e5;
}
.visit-team-info ul {
    padding-top: 15px;
}
.selected { background: red; }

.none-selected { opacity: .5; }
.none-selected div.block-flat > div { opacity: .1; }

.no-selection {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    z-index: 50;
}
.no-selection h1 {
    color: #CCC;
    margin-top: 15%;
    text-align: center;
}

.block-flat.page-header {
    margin-top: 20px;
}
.block-flat.page-header h3 {
    line-height: 30px;
    margin: 0;
}
.no-margin .block-flat.page-header {
    margin-top: 0;
}
.subdued { color: #ccc !important; }
.semi-subdued { color: #aaa; }

 /* INPUT */
 
 input[type="date"] { line-height: normal !important; }
 input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  }

  a.no-style {
      text-decoration: none;
      text-transform: none;
  }
  a.no-style:hover {
      text-decoration: underline;
  }

  /* MULTISELECT DROPDOWN */

  .multiselect-container,
  .multiselect-container > button.dropdown-toggle,
  .multiselect-container > ul.dropdown-menu { width: 100%; }
  .multiselect-container > ul.dropdown-menu {
      display: block;
      margin-left: -1px !important;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0;
  }
  .multiselect-container > ul.dropdown-menu label { text-transform: capitalize; font-size: 15px; }
  .multiselect-container > ul.dropdown-menu li a { border-bottom: none; border-top: 1px solid #eee; }
  .multiselect-container > ul.dropdown-menu li a,
  .multiselect-container > ul.dropdown-menu li.check-state,
  .multiselect-container > ul.dropdown-menu li.no-options { padding: 5px 10px; }