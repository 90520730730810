/*Nav Pills*/
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    background-color: @primary-color;
}

/*List Group*/
.list-group-item:first-child {border-radius:0;-webkit-border-radius:0;}
.list-group-item:last-child {border-radius:0;-webkit-border-radius:0;}
a.list-group-item.active, a.list-group-item.active:hover, a.list-group-item.active:focus {
    background-color: @primary-color;
    border-color: @primary-color;
}
.list-group-item i{min-width:15px;display:inline-block !important;text-align:center;}
.list-group-item h4{margin-bottom:8px;font-size:17px;}
.list-group-item h4{margin-bottom:8px;font-size:15px;}
.list-group-item{
	font-size:12px;
	padding: 13px 15px;
	line-height:22px;
}

a.list-group-item:hover{
	border-left:5px solid @primary-color;
	border-color: @primary-color;
	padding-left: 11px;
}
a.list-group-item:hover + a.list-group-item{
	border-top-color: @primary-color;
}
.list-group .active:hover{
	padding-left: 15px;
	border-left:1px solid @primary-color;
}
