/*Login*/
#cl-wrapper.sign-up-container, #cl-wrapper.error-container{
	margin:0;
}

.sign-up-container .middle-sign-up{
	width:470px;
	left: 50%;
	top: 50%;
	position:absolute;
	margin-top: -282px;
	margin-left: -235px;
}

.forgotpassword-container .middle{
	width:470px;
	margin: 70px auto 0 auto;
}

.sign-up-container .block-flat, .forgotpassword-container .block-flat{
	padding:0;
	border:0;
	overflow:hidden;
	border-radius:2px;
	-webkit-border-radius:2px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
	margin-bottom:20px;
}

.sign-up-container .block-flat .header, .forgotpassword-container .block-flat .header{
	padding: 9px 20px;
	background:@primary-color;
	border-bottom: 1px solid @primary-color;
}

.sign-up-container .block-flat .header h3, .forgotpassword-container .block-flat .header h3 {
	line-height:35px;
	margin:0;
	color:#FFF;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  min-height: 40px;
}

.sign-up-container .header .logo-img, .forgotpassword-container .header .logo-img {
    left: 50%;
    margin-left: -63px;
    margin-top: -13px;
    position: absolute;
    top: 50%;
}
.sign-up-container .block-flat .content, .forgotpassword-container .block-flat .content{
	padding:30px;
	padding-bottom: 20px;
}

.sign-up-container .block-flat .content .form-control,
.forgotpassword-container .block-flat .content .form-control{padding:10px 8px;height:auto;}
.sign-up-container .block-flat .content .form-group {
	margin-bottom:10px;
}

.sign-up-container .block-flat .content .title{margin-top:0;margin-bottom:20px;}

.sign-up-container .block-flat .foot,
.forgotpassword-container .block-flat .foot
{
	text-align:right;
	padding:0 30px 15px;
}

.sign-up-container .block-flat .foot .btn,
.forgotpassword-container .block-flat .foot .btn
{min-width:70px;}

.sign-up-container .block-flat  .input-group-addon,
.forgotpassword-container .block-flat  .input-group-addon
{background:#FAFAFA;}

.sign-up-container .out-links,
.forgotpassword-container .out-links
{padding-right: 3px;}

.sign-up-container .out-links a,
.forgotpassword-container .out-links a
{  color: #C9D4F6; text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);}

@media (max-width: 767px) {
  .sign-up-container .middle-sign-up {
    width:100%;
    position:static;
    margin:0;
  }
  
  .forgotpassword-container .middle{
    width:100%;
  }
  
  .forgotpassword-container{
    padding:20px;
  }
  
  .sign-up-container .middle-sign-up .block-flat{
    border-radius:0;
  }
  
}

