table.dashed tr:nth-child(2n){
  background: #fff;
}
table.dashed td:last-child{border-right: 0;}
table.dashed td{
  padding: 20px;
  border-left: 0;
  border-bottom: 1px dashed #ddd;
}

table th {
    padding: 0 5px;
}

table thead th{
	padding:5px;
	font-size:13px;
	font-weight: 200;
}

table tbody td{
	padding:7px 8px;
	font-size:12px;
}

table td.no-wrap {
    white-space: nowrap;
}

table .primary-emphasis, table .primary-emphasis-dark{
	background: @primary-color;
	color: #FFF;
	border-color: @primary-color;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .primary-emphasis-dark{background-color: @primary-color - 20%;}

table .success-emphasis, table .success-emphasis-dark{
	background: @primary-color - 20%;
	color: #FFF;
	border-color: @primary-color - 20%;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .success-emphasis-dark{background-color:@primary-color - 20%;border-color:@primary-color - 20%;}

table .warning-emphasis, table .warning-emphasis-dark{
	background: #FC9700;
	color: #FFF;
	border-color: #FC9700;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .warning-emphasis-dark{background-color:#fc8800;border-color:#fc8800;}

table .danger-emphasis, table .danger-emphasis-dark{
	background: #DA4932;
	color: #FFF;
	border-color: #DA4932;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .danger-emphasis-dark{background-color:#c8432e;border-color:#c8432e;}


table{
	border-collapse:collapse;
	width:100%;
}

table .right{text-align:right;}
table .left{text-align:left;}
.red thead th{color: @red;}
.blue thead th{color: @blue;}
.violet thead th{color: @dark-purple;}
.green thead th{color: @dark-green;}
table thead th span{color:#333;}

table thead th{
	vertical-align:bottom;
	padding: 10px 8px 5px 8px;
}

table .no-border,
table .list-results {
    border: none !important;
}

table:not(.no-border) thead th{
	border-bottom:1px solid #DADADA;
	border-left:1px solid #DADADA;
	border-top:1px solid #DADADA;
}

table:not(.no-border) thead th:last-child{border-right:1px solid #DADADA;}


table thead span{
	font-size:16px;
	display:block;
}

table td{
	padding: 7px 8px;
}

table.list-results td {
    padding: 25px 10px;
}

table:not(.no-border) td{
	border:1px solid #DADADA;
}

table.interior tr:not(:last-of-type) {
    border-bottom: 1px solid #dadada;
}

table.interior td,
table.interior th {
    border-right: 1px solid #dadada !important;
}

ul.dropdown-menu table td {
    padding: 0 !important;
}

table td .progress{margin:0;}

table.hover tbody tr:hover{
	background: #f3f3f3;
}

table td i{
	font-size:15px;
	display: inline-block;
	text-align: center;
	width: 23px;
}

table:not(.no-border):not(.semi-style) td:last-child{
border-right:1px solid #dadada;
}
 table tr:nth-child(2n){
	background: #f8f8f8;
}

table.list-results tr:nth-child(2n) {
    background: #f5f5f5;
}
 table.row-space {
     border-collapse: separate;
     border-spacing: 0px 20px;
 }
 table.align-top td {
     vertical-align: top !important;
 }
table.row-space tr:not(:first-child) td {
    border-top: 1px solid #e5e5e5;
    padding-top: 30px;
}
table.row-space table tr td {
    border: none;
    padding-top: 0 !important;
}
table.program-audit.row-space tr:first-child td:not(:first-child),
table.has-identifier.row-space tr:first-child td:not(:first-child) {
    padding-top: 40px;
}
table.program-audit.row-space td:not(:first-child),
table.has-identifier.row-space td:not(:first-child) {
    padding-top: 62px;
}
table.shrink-to-fit td,
table td.shrink-to-fit {
    width: 1px;
    white-space: nowrap;
    padding-right: 10px;
}
 table.no-stripes tr:nth-child(2n) {
     background: none;
 }
table.no-strip tr:nth-child(2n){background:transparent;}

/* Datepicker */

.datepicker table th,
.datepicker table tr button {
    padding: 3px 5px !important;
}

/*No-Internal borders in thead*/
table .no-border th{border-left:0;}
table .no-border tr th:first-child{border-left:1px solid #dadada;}


/*No-Internal borders in tbody x and y*/
 table .no-border-x td{border-bottom:0;}
 table .no-border-x tr:last-child td{border-bottom:1px solid #dadada;}

 table .no-border-y td{border-left:0;}
 table .no-border-y tr td:first-child{border-left:1px solid #dadada;}


/*No-External borders general table*/
 table.no-border tr th{border-top:0;}
 table.no-border tr th:first-child{border-left:0;}
 table.no-border tr th:last-child{border-right:0;}

 table.no-border tr td:first-child{border-left:0;}
 table.no-border tr td:last-child{border-right:0;}
 table.no-border tr:last-child td{border-bottom:0;}

table tbody .toggle-details{cursor:pointer;} 
table tbody .details{
  background:#FFF;
}

table tbody td .btn{margin-bottom:0 !important;}
/*list style table*/
table.list.no-border{
  border-top: 0;
}
table.list .items td{
  vertical-align: middle;
  padding: 5px;
}
table.list .items td .progress{
  height: 13px;
}
table.list .items td .progress .progress-bar{
  font-size: 10px;
  line-height: 12px;
}
table.list .items td > span,
.align-top{
  vertical-align: top;
}
table.list .items td p span{
  display: block;
  font-size: 12px;
  line-height: 10px;
  color: #9e9e9e;
}
table tbody td .btn-group .dropdown-menu {margin-top:-1px;min-width:130px;}
table tbody td .btn-group .dropdown-menu li > a {padding:5px 12px;text-align:left;}



table.merge-last-cols tr td:last-child {
    border-left: 0;
}
table.merge-last-cols tr td:nth-last-child(2) {
    border-right: 0;
}

table.vertical-header th {
    border: 1px solid #ddd;
    padding: 7px 8px 0;
}

/* Table within TD */

table td table tr {
    background: none !important;
}

table td table td,
table td table th {
    border-color: #eee;
    border-right: none !important;
    border-left: none !important;
    padding: 4px;
}

table td table tr td:first-child,
table td table tr th:first-child {
    padding-left: 8px;
}

table td table tr td:last-child,
table td table tr th:last-child {
    padding-right: 8px;
}

table td table tr:last-child td,
table td table tr:last-child th {
    border-bottom: none;
}

table td table tr:first-child td,
table td table tr:first-child th {
    border-top: none !important;
}

table tr:nth-child(2n) td table td,
table tr:nth-child(2n) td table th {
    border-color: #ddd;
}

table.no-style tr,
table.semi-style tr {
    background: none;
}
table.no-style tr:only-child td:only-child,
table.semi-style tr:only-child td:only-child {
    padding: 0;
}
table.no-style td,
table.semi-style td {
    border: none;
    border-bottom: none;
    border-right: none;
    border-left: none;
}
table.semi-style tr:not(:first-child) td {
    border-top: 1px solid #DADADA;
}
table.no-style td {
    border-top: none !important;
}

td.medium {
    max-width: 15%;
}
td.add-cell-padding {
    padding: 0px 8px !important;
}