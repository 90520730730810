
.page-header h3:before,
.print-page-header h3:before {
    content: url(../images/ABET_RGB_SM.png);
    display: none;
    margin-top: 30px;
    padding-bottom: 10px;
}

.print-only { display: none; }

@media print {
    .print-pdf {
        page-break-before: always;
        page-break-after: always;
    }

    .pdf-margin { 
        margin: 14px !important; 
    }

    .navbar, #breadcrumbs-container, #main-sidebar, .bottom-navigation-container, i.has-tooltip, .notice.warning, .notice.error, .tab-navigation-container, button, .nav-tabs, .sidebar-logo, .not-printed, .notice {
        display: none !important;
    }

    table.has-activity-history tr.hide {
        display: table-row !important;
    }

    * {
        color: #444 !important;
        border-color: #aaa !important;
        height: auto !important; //to stop IE from cutting off print after first page
    }

    #cl-wrapper {
        margin-bottom: 0; //was previously 800px... removed because it added an extra page in chrome
    }

    /*strong {
        font-weight: normal;
    }*/

    h3, .header h5 {
        color: @abet-orange !important;
    }

    h5, label {
        color: @abet-gray !important;
    }

    h1, h2, h3, h4, h5 {
      page-break-after: avoid;
    }

    h2.no-records {
        font-size: 14px !important;
        font-weight: 400;
        text-align: left !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    table, figure {
      page-break-inside: avoid;
    }

    label {
        font-weight: 400 !important; //Font weight was too low @300.. moved it to 400 and seemed to work
    }

    .block-flat, .page-header {
        margin: 0 !important;
    }

    div, html, body {
        float: none !important;
    }

    .page-header {
        text-align: center;
        margin-top: -10px !important;
        margin-bottom: -40px !important;
        page-break-before: always;
        break-before: always;
    }

    .page-header.block-flat {
        padding: 0 !important;
    }

    .print-page-header h3 {
        color: #444 !important;
    }

    .page-header h3:before,
    .print-page-header h3:before,
    #summary-container label {
        display: block !important;
    }

    #summary-container label {
        color: red !important;
    }

    .block-flat {
        border: none !important;
    }

    .col-sm-6, .block-flat, .page-header {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    div[class*="col-"] {
        width: 100%;
    }

    .survey-group .question-container {
        margin-top: 0 !important;
    }

    .notice .icon {
        width: auto !important;
    }

    .notice .icon i.fa {
        font-size: 25px;
        color: #ccc !important;
    }

    h5.referrer:not(.ref-1) {
        border-top: 1px solid #ccc;
        display: block;
        margin-top: 40px !important;
        padding-top: 40px !important;
    }

    .print-only {
        display: block;
    }

    .do-not-print {
        display: none;
    }
}