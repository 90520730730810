
/*Accordion*/
.accordion{
	margin-bottom:40px;
}

.accordion .panel-heading{
	background: #fff;
	padding:0;
}

.accordion .panel-heading a{
	font-size:15px;
	padding:17px 16px;
	display:block;
	color: @link-color;
}

.accordion .panel-heading a .fa{
	transition:transform 200ms ease-in-out;
	margin-right:10px;
	transform: rotate(90deg);
}

.accordion .panel-heading a.collapsed{
	color:#555;
}

.accordion .panel-heading a.collapsed .fa{
	transform: rotate(0deg);
}

.accordion  .panel-heading + .panel-collapse .panel-body {
	border-top:0;
	padding:3px 20px 20px 34px;
}

.accordion .panel{
	border-radius:2px;
	-webkit-border-radius:2px;
	border-bottom:0;
	border-left:1px solid #ECECEC;
	border-right:1px solid #ECECEC;
	margin-bottom: 5px;
	border-top:0;
	box-shadow:0 1px 0 0 rgba(0, 0, 0, 0.04);
}

.accordion .panel:first-child{
	border-top:0;
}

.accordion .panel:last-child{

}

.accordion.panel-group .panel + .panel {
    margin-top: 0px;
}

/*Primary color accordion*/
.accordion.accordion-color .panel-heading a{
	background-color: @primary-color;
	color:#FFF;
}

.accordion.accordion-color .panel-heading a.collapsed{
	background-color: #FFF;
	color:#555;
}

.accordion.accordion-color .panel-collapse .panel-body{
	background-color: @primary-color;
	color:#FFF;
}

/*Semi color accordion*/
.accordion.accordion-semi .panel-heading a{
	background-color: @primary-color;
	color:#FFF;
	transition:background-color 200ms ease-in-out;
	border-bottom:1px solid @primary-color - 40%;
}

.accordion.accordion-semi .panel-heading.success a{background-color: @dark-green;border-bottom:1px solid @dark-green - 40%;}
.accordion.accordion-semi .panel-heading.warning a{background-color: @orange;border-bottom:1px solid @orange - 40%;}
.accordion.accordion-semi .panel-heading.danger a{background-color: @red;border-bottom:1px solid @red - 40%;}


.accordion.accordion-semi .panel-heading a.collapsed{
	background-color: #FFF;
	color:#555;
	border-bottom:0;
}

.accordion.accordion-semi .panel-collapse .panel-body{
	padding:15px 20px 20px 34px;
}
