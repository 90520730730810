/*Circle*/
.i-circle{
	border:5px solid #F1F1F1;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	height:80px;
	width:80px;
	display:inline-block;
	padding-top:15px;
	margin-bottom:7px;
}

.i-circle i{
	font-size:34px;
	vertical-align:middle;
	color:#666;
}

.i-circle.white i{color: #fff;}
.i-circle.primary i{color: @primary-color;}
.i-circle.success i{color: @dark-green;}
.i-circle.info i{color: @blue;}
.i-circle.warning i{color: @orange;}
.i-circle.danger i{color: @red;}
.i-circle.prusia i{color: @prusia;}
.i-circle.dark i{color: @dark;}
