@treeSpace: 18px;


.tree > div{
 /* overflow: auto; */
}
 
.tree .content{
  padding:0 !important;
}

.tree .title{
 padding: 20px;
}
 
.treeview{
  
}

.treeview label{
  font-weight:400;
  margin:0;
  padding:7px @treeSpace;
  display:block;
  border-bottom:0px solid #E5E5E5;
}

.treeview label:hover, .treeview label.open{
  background:#F9F9F9;
}

.treeview li > ul{
  display:none;
}

.treeview li.open > ul{
  display:block;
}

.treeview ul li{
  border-bottom:0px solid #E5E5E5;
}

.treeview ul li a{
  padding-top: 5px;
  padding-bottom: 5px;
  color:#555;
}

.treeview ul li a:hover{
  background:#F9F9F9;
}

.treeview label i{
  font-size:14px;
  text-align: center;
  width: 24px;
  color: @link-color;
}

.treeview ul li label{padding-left: @treeSpace * 2;}
.treeview ul li ul li label{padding-left: (@treeSpace * 3);}
.treeview ul li ul li ul li label{padding-left: (@treeSpace * 4);}
.treeview ul li ul li ul li ul li label{padding-left: (@treeSpace * 5);}

.treeview ul li a{padding-left: @treeSpace * 2;}
.treeview ul li ul li a{padding-left: (@treeSpace * 3);}
.treeview ul li ul li ul li a{padding-left: (@treeSpace * 4);}
.treeview ul li ul li ul li a{padding-left: (@treeSpace * 5);}

.treeview .tree-toggler{
  cursor:pointer;
}