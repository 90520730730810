.dropdown-menu{
  border: 0;
  box-shadow:0 2px 3px rgba(0, 0, 0, 0.176);
}

.dropdown-menu > li:hover {
    cursor: pointer;
}

/*.dropdown-menu > li > a, .dropdown-menu  li > a {
    font-size: 12px;
    padding: 9px 20px;
	clear: both;
    color: #777;
    display: block;
    font-weight: normal;
    line-height: 1.42857;
    white-space: nowrap;
    text-transform: none;
    border-bottom: 1px solid #eee;
}*/
/* Target result type, i.e. Institution, Person */
.omnisearch .dropdown-menu > li span:first-of-type {
    font-family: @header-font;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    margin-right: 2px;
}
/* Make search query orange */
.dropdown-menu > li span:nth-child(2) strong {
    color: @abet-orange;
}
/* Make search query gray on hover */
.dropdown-menu > .active span:nth-child(2) strong {
    color: black;
}
.dropdown-menu > li:last-child > a, .dropdown-menu  li:last-child > a {
    border-bottom: none;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus{
    background: @medium-gray + 50%;
    color: #333;
}
.dropdown-header {
    color: @primary-color;
	margin-top: 6px;
	padding-left: 11px;
	cursor:pointer;
}
.dropdown-header i{font-size: 14px;margin-right:4px;vertical-align: middle;}

.navbar-nav .dropdown .dropdown-menu{
	background-clip: none;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
}
.navbar-nav .dropdown.profile_menu .dropdown-menu{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.dropdown.profile_menu .dropdown-menu:before{
  background: url(../images/menu-tail-up.png) no-repeat ;
  top: -10px;
  content: "";
  right: 10px;
  display: inline-block;
  height: 43px;
  width: 17px;
  position: absolute;
  }
  .not-nav .dropdown-menu:before{
  background: url(../images/menu-tail-up.png) no-repeat ;
  top: -10px;
  content: "";
  left: 13px;
  display: inline-block;
  height: 43px;
  width: 17px;
  position: absolute;
  }

.col-menu-2{
	min-width:405px;
	padding-bottom: 10px;
  padding-top: 7px;
}

.col-menu-2 > li:first-child ul{
	border-right: 1px solid #E8E8E8;
}

.col-menu-2 > li > ul > li a{
padding-left: 32px;
}

.col-menu-2 > li > ul{
  padding:0;
}

.col-menu-2 > li > ul > li{
  list-style:none;
}
/*END - Dropdown menu*/