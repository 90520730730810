/*Buttons*/
.btn{
  border-color: #CCCCCC;
	border-radius:0;
-webkit-border-radius:0;
	outline:none;
	margin-bottom: 5px !important;
	margin-left: 3px;
	font-size: 13px;
	padding: 7px 11px;
}

.btn-default, .btn-default:focus {background-color: #FFFFFF;border-color: #CCCCCC;color: #5E5E5E; }
.btn-default i{color:#444;}
.btn-default:active{box-shadow:none; background: #f5f5f5;}

.btn-primary, .btn-primary:focus {background-color: @primary-color;border-color: transparent; color:#fff;}
.btn-primary:hover, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {background-color: @primary-color + 10% ;border-color: transparent;}
.btn-primary:active{box-shadow:none; background: @primary-color - 10%;}
 
.btn-success, .btn-success:focus {background-color: @dark-green;border-color: transparent;}
.btn-success:hover, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success{ background-color: @dark-green + 10%; border-color: transparent;}
.btn-success:active{box-shadow:none; background: @dark-green - 10%;}


.btn-info, .btn-info:focus {background-color: @blue;border-color: transparent;}
.btn-info:hover, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {background-color: @blue + 10%;	border-color: transparent;}
.btn-info:active{box-shadow:none; background: @blue - 10%;}


.btn-warning, .btn-warning:focus {background-color: @orange;border-color: transparent;}
.btn-warning:hover, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning{ background-color: @orange + 10%;	border-color: transparent;}
.btn-warning:active{box-shadow:none; background: @orange - 10%;}


.btn-danger, .btn-danger:focus { background-color: @red; border-color: transparent;}
.btn-danger:hover, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger{ background-color: @red + 10%; border-color: transparent;}
.btn-danger:active{box-shadow:none; background: @red - 10%;}

.btn-prusia, .btn-prusia:focus { background-color: @prusia; border-color: transparent; color: #fff;}
.btn-prusia:hover, .btn-prusia:active, .btn-prusia.active, .open .dropdown-toggle.btn-prusia{ color: #fff;background-color: @prusia + 10%; border-color: transparent;}
.btn-prusia:active{box-shadow:none; background: @prusia - 10%;}

.btn-dark, .btn-dark:focus { background-color: @dark; border-color: transparent; color: #fff;}
.btn-dark:hover, .btn-dark:active, .btn-dark.active, .open .dropdown-toggle.btn-dark{ color: #fff;background-color: @dark + 10%; border-color: transparent;}
.btn-dark:active{box-shadow:none; background: @dark - 10%;}

.btn-rad{border-radius:2px;-webkit-border-radius:2px;}

.btn-group.horizontal > .btn{border-color: rgba(0,0,0,0.08);}

.btn i{font-size:14px;margin-right: 2px;display:inline-block; min-width: 10px;}
.btn-group-vertical{margin: 3px 3px;}

.btn-block{ width: 100%;}

.close{
  text-shadow: none;
  font-size: 32px;
  margin-top: -6px;
}

/*Transparent Buttons*/
.btn-trans{
  background-color: #fff;
  border-color: #CCCCCC;
  color: #5E5E5E;
}
.btn-trans.btn-primary{
  background-color: #fff;
  border-color: @primary-color;
  color: @primary-color;
}
.btn-trans.btn-primary:hover{
  background-color: @primary-color;
  color: #fff;
}
.btn-trans.btn-success{
  background-color: #fff;
  border-color: @dark-green;
  color: @dark-green;
}
.btn-trans.btn-success:hover{
  background-color: @dark-green;
  color: #fff;
}
.btn-trans.btn-info{
  background-color: #fff;
  border-color: @blue;
  color: @blue;
}
.btn-trans.btn-info:hover{
  background-color: @blue;
  color: #fff;
}
.btn-trans.btn-warning{
  background-color: #fff;
  border-color: @orange;
  color: @orange;
}
.btn-trans.btn-warning:hover{
  background-color: @orange;
  color: #fff;
}
.btn-trans.btn-danger{
  background-color: #fff;
  border-color: @red;
  color: @red;
}
.btn-trans.btn-danger:hover{
  background-color: @red;
  color: #fff;
}
.btn-trans.btn-prusia{
  background-color: #fff;
  border-color: @prusia;
  color: @prusia;
}
.btn-trans.btn-prusia:hover{
  background-color: @prusia;
  color: #fff;
}
.btn-trans.btn-dark{
  background-color: #fff;
  border-color: @dark;
  color: @dark;
}
.btn-trans.btn-dark:hover{
  background-color: @dark;
  color: #fff;
}

/*Monochromatic tones*/

/*MONO1*/
.btn-mono1.btn-primary{background-color: @primary-color - 10%;}
.btn-mono1.btn-primary:hover{background-color: @primary-color - 5%;}
.btn-mono1.btn-success{background-color: @dark-green - 10%;}
.btn-mono1.btn-success:hover{background-color: @dark-green - 5%;}
.btn-mono1.btn-info{background-color: @blue - 10%;}
.btn-mono1.btn-info:hover{background-color: @blue - 5%;}
.btn-mono1.btn-warning{background-color: @orange - 10%;}
.btn-mono1.btn-warning:hover{background-color: @orange - 5%;}
.btn-mono1.btn-danger{background-color: @red - 10%;}
.btn-mono1.btn-danger:hover{background-color: @red - 5%;}
.btn-mono1.btn-prusia{background-color: @prusia - 10%;}
.btn-mono1.btn-prusia:hover{background-color: @prusia - 5%;}
.btn-mono1.btn-dark{background-color: @dark - 10%;}
.btn-mono1.btn-dark:hover{background-color: @dark - 5%;}

/*MONO2*/
.btn-mono2.btn-primary{background-color: @primary-color - 20%;}
.btn-mono2.btn-primary:hover{background-color: @primary-color - 15%;}
.btn-mono2.btn-success{background-color: @dark-green - 20%;}
.btn-mono2.btn-success:hover{background-color: @dark-green - 15%;}
.btn-mono2.btn-info{background-color: @blue - 20%;}
.btn-mono2.btn-info:hover{background-color: @blue - 15%;}
.btn-mono2.btn-warning{background-color: @orange - 20%;}
.btn-mono2.btn-warning:hover{background-color: @orange - 15%;}
.btn-mono2.btn-danger{background-color: @red - 20%;}
.btn-mono2.btn-danger:hover{background-color: @red - 15%;}
.btn-mono2.btn-prusia{background-color: @prusia - 20%;}
.btn-mono2.btn-prusia:hover{background-color: @prusia - 15%;}
.btn-mono2.btn-dark{background-color: @dark - 20%;}
.btn-mono2.btn-dark:hover{background-color: @dark - 15%;}
/*MONO3*/
.btn-mono3.btn-primary{background-color: @primary-color - 30%;}
.btn-mono3.btn-primary:hover{background-color: @primary-color - 25%;}
.btn-mono3.btn-success{background-color: @dark-green - 30%;}
.btn-mono3.btn-success:hover{background-color: @dark-green - 25%;}
.btn-mono3.btn-info{background-color: @blue - 30%;}
.btn-mono3.btn-info:hover{background-color: @blue - 25%;}
.btn-mono3.btn-warning{background-color: @orange - 30%;}
.btn-mono3.btn-warning:hover{background-color: @orange - 25%;}
.btn-mono3.btn-danger{background-color: @red - 30%;}
.btn-mono3.btn-danger:hover{background-color: @red - 25%;}
.btn-mono3.btn-prusia{background-color: @prusia - 30%;}
.btn-mono3.btn-prusia:hover{background-color: @prusia - 25%;}
.btn-mono3.btn-dark{background-color: @dark - 30%;}
.btn-mono3.btn-dark:hover{background-color: @dark - 25%;}
/*MONO4*/
.btn-mono4.btn-primary{background-color: @primary-color - 40%;}
.btn-mono4.btn-primary:hover{background-color: @primary-color - 35%;}
.btn-mono4.btn-success{background-color: @dark-green - 40%;}
.btn-mono4.btn-success:hover{background-color: @dark-green - 35%;}
.btn-mono4.btn-info{background-color: @blue - 40%;}
.btn-mono4.btn-info:hover{background-color: @blue - 35%;}
.btn-mono4.btn-warning{background-color: @orange - 40%;}
.btn-mono4.btn-warning:hover{background-color: @orange - 35%;}
.btn-mono4.btn-danger{background-color: @red - 40%;}
.btn-mono4.btn-danger:hover{background-color: @red - 35%;}
.btn-mono4.btn-prusia{background-color: @prusia - 40%;}
.btn-mono4.btn-prusia:hover{background-color: @prusia - 35%;}
.btn-mono4.btn-dark{background-color: @dark - 40%;}
.btn-mono4.btn-dark:hover{background-color: @dark - 35%;}

/*Specific icons size*/

.btn-lg{padding:12px 14px;  font-size: 15px; font-weight: 300;}
.btn-lg i{font-size:18px;}

.btn-sm{padding:4px 7px;font-size:12px;}
.btn-sm i{font-size:14px;padding-left:2px;}

.btn-xs{padding:2px 6px;font-size: 11px;}
.btn-xs i{font-size:12px;}

.btn-shadow{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.12);}
.btn-shadow.btn-success{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-primary{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-warning{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-danger{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-info{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-prusia{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-dark{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}

.btn-flat, .btn-flat:focus{
	box-shadow:none;
}

.btn-primary .caret, .btn-success .caret, .btn-warning .caret, .btn-danger .caret, .btn-info .caret, .btn-prusia .caret, .btn-dark .caret { border-top-color: #FFFFFF;}

.btn-group-vertical > .btn{
	margin-bottom:0 !important;
}

.btn-group-vertical > .btn:first-child{margin-left:0;border-radius:0 !important;-webkit-border-radius:0 !important;}
.btn-group-vertical > .btn:last-child{border-radius:0 !important;-webkit-border-radius:0 !important;}

/*Social buttons*/
.btn-facebook i{color:#3b5998;}
.btn-twitter i{color:#00aced;}
.btn-google-plus i{color:#dd4b39;}
.btn-dribbble i{color:#ea4c89;}
.btn-linkedin i{color:#0e76a8;}
.btn-youtube i{color:#c4302b;}
.btn-pinterest i{color:#c8232c;}
.btn-tumblr i{color:#34526f;}
.btn-flickr i{color:#ff0084;}

.btn-facebook.bg {background:#3b5998;color:#fff;border:0;}
.btn-twitter.bg {background:#00aced;color:#fff;border:0;}
.btn-google-plus.bg {background:#dd4b39;color:#fff;border:0;}
.btn-dribbble.bg {background:#ea4c89;color:#fff;border:0;}
.btn-linkedin.bg {background:#0e76a8;color:#fff;border:0;}
.btn-youtube.bg {background:#c4302b;color:#fff;border:0;}
.btn-pinterest.bg {background:#c8232c;color:#fff;border:0;}
.btn-tumblr.bg{background:#34526f;color:#fff;border:0;}
.btn-flickr.bg{background:#0063dc;color:#fff;border:0;}
.btn-github.bg{background:#333;color:#fff;border:0;}
/*active socials*/
.btn-facebook.bg:active, .btn-facebook.bg.active {background:#3b5998 - 20%;}
.btn-twitter.bg:active, .btn-twitter.bg.active{background:#00aced - 20%;}
.btn-google-plus.bg:active, .btn-google-plus.bg.active{background:#dd4b39 - 20%;}
.btn-dribbble.bg:active, .btn-dribbble.bg.active{background:#ea4c89 - 20%;}
.btn-linkedin.bg:active, .btn-linkedin.bg.active{background:#0e76a8 - 20%;}
.btn-youtube.bg:active, .btn-youtube.bg.active{background:#c4302b - 20%;}
.btn-pinterest.bg:active, .btn-pinterest.bg.active{background:#c8232c - 20%;}
.btn-tumblr.bg:active, .btn-tumblr.bg.active{background:#34526f - 20%;}
.btn-flickr.bg:active, .btn-flickr.bg.active{background:#0063dc - 20%;}
.btn-github.bg:active, .btn-github.bg.active{background:#333 - 20%;}

.btn.bg i{color:#FFF;}
.btn:active, .btn.active{ box-shadow: none; }
.btn:focus{outline:none;}

.btn-group.btn-blurbg{
	background: url("../images/blur_bg.png") no-repeat;
	padding:40px;
	margin-bottom:20px;
	box-shadow:1px 1px 2px rgba(0, 0, 0, 0.15);
}

.btn.btn-transparent{
	border-top: 1px solid #fff;
	border-bottom: 1px solid rgba(255,255,255,0.5);
	border-left: 1px solid rgba(255,255,255,0.4);
	border-right: 1px solid rgba(255,255,255,0.4);
	background: rgba(255,255,255,0.2);
	color:#fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
}

.btn.btn-transparent:hover{
	background: rgba(255,255,255,0.32);
}

.video-player{
	position:relative;
	display:inline-block;
	box-shadow:1px 1px 2px rgba(0, 0, 0, 0.15);
}

.video-player video{display:block;max-width:320px;width:100%;}

.video-player .btn-controls{
	text-align:center;
	bottom:5px;
	position:absolute;
	width:100%;
}

.video-player .btn-controls .btn-group{
	margin:0;
}

.video-player .btn-controls .btn-group{float:none;}
button::-moz-focus-inner {
  border: 0;
}

.btn-group .dropdown-menu{
  border-radius:1px;
}

/*disable*/
.btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled:active, .btn-primary[disabled]:active, fieldset[disabled] .btn-primary:active, .btn-primary.disabled.active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary.active{background-color: @primary-color - 40%; border-color: @primary-color - 40%; }