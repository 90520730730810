
/*Icons*/
.fa-mobile-phone{font-size:18px;}
.icon-16{font-size:16px !important;}
.icon-19{font-size:19px !important;}
.icon-24{font-size:24px !important;}
.icon-32{font-size:32px !important;}
.icon-64{font-size:64px !important;}
.icon-show i{font-size:14px !important;margin-right:5px;width:20px;}
.icon-show > div{margin: 5px 0;}
