.multiselect-container {
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0
}

.multiselect-container .input-group {
    margin: 5px
}

.multiselect-container > li {
    padding: 0
}

.multiselect-container > li > a.multiselect-all label {
    font-weight: bold
}

.multiselect-container > li > label.multiselect-group {
    margin: 0;
    padding: 3px 20px 3px 20px;
    height: 100%;
    font-weight: bold
}

.multiselect-container > li > a > label {
    margin: 0;
    height: 100%;
    cursor: pointer;
    font-weight: normal
}

.multiselect-container > li > a > label.radio, .multiselect-container > li > a > label.checkbox {
    margin: 0
}

.multiselect-container > li > a > label > input[type="checkbox"] {
    margin-bottom: 5px
}

.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}
