﻿/* Flexbox classes from Bootstrap 4 */

.d-flex {
    display: flex;
}

.justify-content-start {
    justify-content: flex-start
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-between {
    justify-content: space-between;
}

.flex-align-items-start {
    align-items: flex-start;
}

.flex-align-items-center {
    align-items: center;
}

.flex-align-items-end {
    align-items: flex-end;
}

.flex-align-items-baseline {
    align-items: baseline;
}

.flex-0 {
    flex-grow: 0;
    flex-shrink: 0;
}

.flex-1 {
    flex-grow: 1;
    flex-shrink: 1;
}

.flex-2 {
    flex-grow: 2;
    flex-shrink: 2;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-grow-0 {
    flex-grow: 0;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-none {
    flex: none;
}
