@font-face {
    font-family: 'Egyptienne';
    src: url('../fonts/webfonts/EgyptienneFLT-Roman.eot');
    src: url('../fonts/webfonts/EgyptienneFLT-Roman.eot?#iefix') format('embedded-opentype'),
         url('../fonts/webfonts/EgyptienneFLT-Roman.woff2') format('woff2'),
         url('../fonts/webfonts/EgyptienneFLT-Roman.woff') format('woff'),
         url('../fonts/webfonts/EgyptienneFLT-Roman.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
  
@font-face {
    font-family: 'Egyptienne';
    src: url('../fonts/webfonts/EgyptienneFLT-Italic.eot');
    src: url('../fonts/webfonts/EgyptienneFLT-Italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/webfonts/EgyptienneFLT-Italic.woff2') format('woff2'),
         url('../fonts/webfonts/EgyptienneFLT-Italic.woff') format('woff'),
         url('../fonts/webfonts/EgyptienneFLT-Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
   
@font-face {
    font-family: 'Egyptienne';
    src: url('../fonts/webfonts/EgyptienneFLT-Bold.eot');
    src: url('../fonts/webfonts/EgyptienneFLT-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/webfonts/EgyptienneFLT-Bold.woff2') format('woff2'),
         url('../fonts/webfonts/EgyptienneFLT-Bold.woff') format('woff'),
         url('../fonts/webfonts/EgyptienneFLT-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
   
@font-face {
    font-family: 'Egyptienne';
    src: url('../fonts/webfonts/EgyptienneFLT-Black.eot');
    src: url('../fonts/webfonts/EgyptienneFLT-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/webfonts/EgyptienneFLT-Black.woff2') format('woff2'),
         url('../fonts/webfonts/EgyptienneFLT-Black.woff') format('woff'),
         url('../fonts/webfonts/EgyptienneFLT-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}


/* Campton */
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton Thin.eot');
  src: url('../fonts/webfonts/Campton Thin.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton Thin.woff2') format('woff2'),
       url('../fonts/webfonts/Campton Thin.woff') format('woff'),
       url('../fonts/webfonts/Campton Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton ThinItalic.eot');
  src: url('../fonts/webfonts/Campton ThinItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton ThinItalic.woff2') format('woff2'),
       url('../fonts/webfonts/Campton ThinItalic.woff') format('woff'),
       url('../fonts/webfonts/Campton ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton ExtraLight.eot');
  src: url('../fonts/webfonts/Campton ExtraLight.eot?#iefix') format('embedded-opentype'),
	   url('../fonts/webfonts/Campton ExtraLight.woff2') format('woff2'),
	   url('../fonts/webfonts/Campton ExtraLight.woff') format('woff'),
	   url('../fonts/webfonts/Campton ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton ExtraLightItalic.eot');
  src: url('../fonts/webfonts/Campton ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
	   url('../fonts/webfonts/Campton ExtraLightItalic.woff2') format('woff2'),
	   url('../fonts/webfonts/Campton ExtraLightItalic.woff') format('woff'),
	   url('../fonts/webfonts/Campton ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton Light.eot');
  src: url('../fonts/webfonts/Campton Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton Light.woff2') format('woff2'),
       url('../fonts/webfonts/Campton Light.woff') format('woff'),
       url('../fonts/webfonts/Campton Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton LightItalic.eot');
  src: url('../fonts/webfonts/Campton LightItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton LightItalic.woff2') format('woff2'),
       url('../fonts/webfonts/Campton LightItalic.woff') format('woff'),
       url('../fonts/webfonts/Campton LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton Book.eot');
  src: url('../fonts/webfonts/Campton Book.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton Book.woff2') format('woff2'),
       url('../fonts/webfonts/Campton Book.woff') format('woff'),
       url('../fonts/webfonts/Campton Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton BookItalic.eot');
  src: url('../fonts/webfonts/Campton BookItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton BookItalic.woff2') format('woff2'),
       url('../fonts/webfonts/Campton BookItalic.woff') format('woff'),
       url('../fonts/webfonts/Campton BookItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton Medium.eot');
  src: url('../fonts/webfonts/Campton Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton Medium.woff2') format('woff2'),
       url('../fonts/webfonts/Campton Medium.woff') format('woff'),
       url('../fonts/webfonts/Campton Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton MediumItalic.eot');
  src: url('../fonts/webfonts/Campton MediumItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton MediumItalic.woff2') format('woff2'),
       url('../fonts/webfonts/Campton MediumItalic.woff') format('woff'),
       url('../fonts/webfonts/Campton MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton SemiBold.eot');
  src: url('../fonts/webfonts/Campton SemiBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton SemiBold.woff2') format('woff2'),
       url('../fonts/webfonts/Campton SemiBold.woff') format('woff'),
       url('../fonts/webfonts/Campton SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton SemiBoldItalic.eot');
  src: url('../fonts/webfonts/Campton SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton SemiBoldItalic.woff2') format('woff2'),
       url('../fonts/webfonts/Campton SemiBoldItalic.woff') format('woff'),
       url('../fonts/webfonts/Campton SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton Bold.eot');
  src: url('../fonts/webfonts/Campton Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton Bold.woff2') format('woff2'),
       url('../fonts/webfonts/Campton Bold.woff') format('woff'),
       url('../fonts/webfonts/Campton Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton BoldItalic.eot');
  src: url('../fonts/webfonts/Campton BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton BoldItalic.woff2') format('woff2'),
       url('../fonts/webfonts/Campton BoldItalic.woff') format('woff'),
       url('../fonts/webfonts/Campton BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton ExtraBold.eot');
  src: url('../fonts/webfonts/Campton ExtraBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton ExtraBold.woff2') format('woff2'),
       url('../fonts/webfonts/Campton ExtraBold.woff') format('woff'),
       url('../fonts/webfonts/Campton ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton ExtraBoldItalic.eot');
  src: url('../fonts/webfonts/Campton ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton ExtraBoldItalic.woff2') format('woff2'),
       url('../fonts/webfonts/Campton ExtraBoldItalic.woff') format('woff'),
       url('../fonts/webfonts/Campton ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton Black.eot');
  src: url('../fonts/webfonts/Campton Black.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton Black.woff2') format('woff2'),
       url('../fonts/webfonts/Campton Black.woff') format('woff'),
       url('../fonts/webfonts/Campton Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfonts/Campton BlackItalic.eot');
  src: url('../fonts/webfonts/Campton BlackItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfonts/Campton BlackItalic.woff2') format('woff2'),
       url('../fonts/webfonts/Campton BlackItalic.woff') format('woff'),
       url('../fonts/webfonts/Campton BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
