.hthin{
	font-family: "Raleway",Helvetica,sans-serif !important;
	font-weight:100 !important;
}

blockquote {
  border-left: 5px solid @primary-color;
}
blockquote.pull-right{border-right: 5px solid @primary-color;}

.fa-spin{animation: 1.2s linear 0s normal none infinite spin;}
/*Pre & Code*/
pre {border-radius:2px;-webkit-border-radius:2px;margin-top:10px;}

/*CONTENT*/
h5 {
    line-height: 1.4;
}

.cont h2{
   font-family: "Raleway",Helvetica,sans-serif;
    font-size: 32px;
    font-weight: 100;
    line-height: 38px;
    margin: 0 0 10px 0;
}

.cont h5{
	font-size: 13px;
    font-weight: 200;
    line-height: 21px;
}

.cont p{
	font-size:13px;
	color: #333;
    font-size: 13px;
    font-weight: 200;
    line-height: 23px;
}

.cont b{font-weight: 400;}

/*Media List*/
.media-heading{
  margin: 0 0 5px !important;
}
/*Colors*/
.color-primary {color: @primary-color !important;}
.color-success {color: @dark-green !important;}
.color-warning {color: @orange !important;}
.color-danger {color: @red !important;}
/*Well*/
.well p:last-child{margin:0;}
