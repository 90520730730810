﻿app-editor {
    margin: 10px 0;
    display: block;
    position: relative;
}

app-editor > div[contenteditable] {
    border: 1px solid @abet-gray;
    display: block;
    width: 100%;
    min-height: 10em;
}

app-editor > div[contenteditable]:focus {
    outline: none;
}

app-editor > div[contenteditable] mark {
    background-color: transparent;
}

app-editor > div[contenteditable] mark {
    padding: 0;
}

app-editor > div[contenteditable] mark.highlight {
    background-color: @abet-orange;
}

app-editor > div[contenteditable] p {
    margin: 0.5em;
}

app-editor > .toolbar {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 100%;
    visibility: hidden;
}

app-editor > .toolbar > button {
    border: none;
    margin: 0;
    border-top: 1px solid @abet-gray;
    border-right: 1px solid @abet-gray;
    background-color: white;
    color: black;
    height: 1.6em; /* TODO: tweak this for Chrome */
}

app-editor > .toolbar > button:disabled {
    color: gray;
}

app-editor > .toolbar > button:first-child {
    border-left: 1px solid @abet-gray;
}

app-editor .comment-form {
    display: grid;
    position: absolute;
    width: 300px;
    grid-template-columns: 1fr repeat(2, min-content);
    border: 1px solid @abet-gray;
    display: none;
}

app-editor .comment-form textarea {
    resize: none;
    height: 3em;
    border: none;
    border-right: 1px solid @abet-gray;
}

app-editor .comment-form button {
    border: none;
    background-color: #fff;
}
