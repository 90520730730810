/*TABS*/
.nav-tabs {
	border-bottom:1px solid transparent;
}

.nav-tabs > li { 
    font-size: 14px;
	margin-right: 2px;
}
.nav-tabs > li.active{ 
	position:relative;
}
.nav-tabs.wall > li {margin-bottom: 2px;}
.nav-tabs > li > a {
	padding:9px 15px;
	font-size:13px;
	margin-right: 0;  
	min-width: 80px;
  text-align: center;
}
.nav-tabs.wall > li > a {
	padding: 9px 15px;
	font-size:13px;
	margin-right: 0;  
	min-width: 20px;
  color: #555;
  text-align: center;
  border: none;
}
.nav-tabs.wall > li > a:hover, .nav-tabs.wall > li > a:active{
	background: transparent;
	border: none;
	color: @primary-color;
	border-radius:2px;
	-webkit-border-radius:2px 2px 0 0;
}
.nav-tabs.wall > li.active > a:after, .nav-tabs.wall > li.active > a:hover:after, .nav-tabs.wall > li.active > a:focus:after {
  content:"";
	display:block;
	height: 10px;
	background: url('../images/menu-tail-up.png') no-repeat;
	position:absolute;
	bottom: -3px;
  top: auto;
	left: 50%;
  margin-left: -8px;
	width: 17px;
}
.nav-tabs.wall > li.active > a, .nav-tabs.wall > li.active > a:hover, .nav-tabs.wall > li.active > a:focus {
	background: transparent;
  color: @primary-color;
  border: none;
	border-radius: 2px;
	-webkit-border-radius:0;
	border-bottom:0;
	padding-bottom: 10px;
  padding-top: 9px;
	box-shadow: none;
  position:static;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:active{
	background:transparent;
	border:1px solid transparent;
	color: @primary-color - 20%;
	border-radius:2px 2px 0 0;
	-webkit-border-radius:2px 2px 0 0;
}


.nav-tabs > li.active > a:after, .nav-tabs > li.active > a:hover:after, .nav-tabs > li.active > a:focus:after {
	content:"";
	display:block;
	height:2px;
	background: transparent;
	position:absolute;
	top:0;
	left:0;
	width:100%;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	border-top:2px solid @primary-color;
	border-radius:0;
	-webkit-border-radius:0;
	border-bottom:0;
	padding-bottom: 10px;
    padding-top: 8px;
	box-shadow: 1px -3px 4px -3px rgba(0, 0, 0, 0.08);
	border-left:1px solid #ECECEC;
	border-right:1px solid #ECECEC;
	position:static;
}
.tab-content{
	background:#fff;
	padding:20px;
  border-bottom: 1px solid #E2E2E2;
  border-left: 1px solid #ECECEC;
  border-radius: 0 3px 3px;
  -webkit-border-radius: 0 3px 3px;
  border-right: 1px solid #ECECEC;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
	margin-bottom: 40px;
}
.tab-content #picture .fileinput .thumbnail{
  border: 2px dashed #ddd;
}
.tab-content #picture .fileinput .thumbnail h2{
  top: 30%;
  position: relative;
}

.tab-content h3, h2, h1:first-child{margin-top:0;}

/*Tab Bottom*/
.tab-bottom .nav-tabs{margin-bottom:40px;}
.tab-bottom .tab-content{margin-bottom:0;}
.tab-bottom .nav-tabs > li.active > a:after,.tab-bottom  .nav-tabs > li.active > a:hover:after,.tab-bottom  .nav-tabs > li.active > a:focus:after {
	bottom:0;
	top:auto;
}
.tab-bottom .nav-tabs > li.active > a,.tab-bottom  .nav-tabs > li.active > a:hover,.tab-bottom  .nav-tabs > li.active > a:focus {
	border-top:0;
	border-bottom:2px solid @primary-color;
	margin-top:-1px;
	box-shadow: 1px 3px 4px -3px rgba(0, 0, 0, 0.08);
}

.tab-bottom  .tab-content{
	border-radius: 3px 3px 3px 0;
	-webkit-border-radius: 3px 3px 3px 0;
}

/*Tab Left*/
.tab-left .nav-tabs{
	float:left;
}

.tab-left .nav-tabs > li{
	float:none;
	margin-right:0;
	margin-bottom:0;
}
.tab-left .nav-tabs > li > a{
	text-align:left;
	min-width:38px;
	padding:12px 10px;
	border-radius: 0;
	-webkit-border-radius: 0;
}


.tab-left .nav-tabs > li .fa{font-size:14px;}

.tab-left .nav-tabs > li.active > a:after,.tab-left  .nav-tabs > li.active > a:hover:after,.tab-left  .nav-tabs > li.active > a:focus:after {
	bottom:0;
	top:auto;
	width:2px;
	height:100%;
}

.tab-left .nav-tabs > li.active > a,.tab-left  .nav-tabs > li.active > a:hover,.tab-left  .nav-tabs > li.active > a:focus {
	border-top:0;
	border-bottom:1px solid #ECECEC;
	border-right:0;
	border-left:2px solid @primary-color;
	margin-right:-1px;
	box-shadow: -1px 3px 4px -3px rgba(0, 0, 0, 0.08);
	padding:13px 10px 12px 9px;
}
.tab-left  .tab-content{
	overflow:auto;
	border-radius: 0 3px 3px 3px;
	-webkit-border-radius: 0 3px 3px 3px;
}

/*Tab Right*/
.tab-right .nav-tabs{
	float:right;
}

.tab-right  .tab-content{
	overflow:auto;
	border-radius: 3px 0 3px 3px;
	-webkit-border-radius: 3px 0 3px 3px;
}
.tab-right .nav-tabs > li{
	float:none;
	margin-right:0;
	margin-bottom:2px;
}
.tab-right .nav-tabs > li > a{
	text-align:left;
	min-width:38px;
	padding:12px 10px;
}

.tab-right .nav-tabs > li .fa{font-size:14px;}

.tab-right .nav-tabs > li.active > a:after,.tab-right  .nav-tabs > li.active > a:hover:after,.tab-right  .nav-tabs > li.active > a:focus:after {
	bottom:0;
	right:0;
	left:auto;
	top:auto;
	width:2px;
	height:100%;
}

.tab-right .nav-tabs > li.active > a,.tab-right  .nav-tabs > li.active > a:hover,.tab-right  .nav-tabs > li.active > a:focus {
	border-top:0;
	border-bottom:1px solid #ECECEC;
	border-left:0;
	border-right:2px solid @primary-color;
	margin-left:-1px;
	box-shadow: 1px 3px 4px -3px rgba(0, 0, 0, 0.08);
	padding:13px 10px 12px 12px;
}

.shadow.tab-content{
	border-bottom:1px solid #DADADA;
	border-left:1px solid #DADADA;
	border-right:1px solid #DADADA;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
}
