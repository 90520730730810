/*Progress*/
.progress {
	height:18px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
}
.progress .progress-bar {
    line-height: 16px;
}
.progress.small { 
    height: 10px;
}
.progress.small .progress-bar {
    line-height: 9px;
}
.progress.extra-small{height: 5px;}
.progress.big{height: 24px;}
.progress.big .progress-bar{line-height: 22px;}



.progress-bar-info {
    background-color: @blue;
}

.progress-bar-success {
    background-color: @dark-green;
}
.progress-bar-warning {
    background-color: @yellow;
}
.progress-bar-danger {
    background-color: @red;
}
.progress-bar-primary {
    background-color: @primary-color;
}
