/*Responsive CSS*/
@media (min-width: 1400px) {
	.cl-sidebar .cl-vnavigation{
		display:block !important;
	}
}

@media (min-width: 1180px) and (max-width: 1399px) {
	.cl-sidebar .cl-vnavigation{
		display:block !important;
	}
}

/*Collapse sidebar and remove toggle*/
@media (min-width: 768px) and (max-width: 1179px) {
    #main-sidebar .tooltip { display: block !important; }
	.butstyle{
		width:30.9%;
	}
	.butstyle:first-child{margin-left:0;}

	.navbar-default  .navbar-brand{
		background-position: 0 11px;
		height:50px;
		width: 45px;
        padding-left: 26px;
	}
	.navbar-default .navbar-brand span{
		display:none;
	}
    #head-nav.topbar .navbar-collapse{ padding-left:0;}
	.cl-sidebar {width:55px;box-shadow:none;}
    .sidebar-toggle { display: none; }
    #head-nav.topbar .horizontal>li>a{padding:22px 16px;}
    #pcont{
        margin-left:55px;
    }
    .cl-vnavigation i.fa { margin-right: 0; }
    #cl-wrapper.fixed-menu .menu-space {
        width:55px;
    }
  
    .cl-vnavigation li {position:relative;}
    .cl-vnavigation>.parent>a:before{display: none;}
    .cl-vnavigation li a i{margin:0;font-size:17px;}
    .cl-vnavigation li a span{opacity:0;max-width:0;max-height:0;} 
    .cl-sidebar .side-user{display:none;}
    .cl-sidebar .cl-vnavigation{
	    display:block !important;
    }

    /*TODO: make this work with JS instead*/
    .cl-vnavigation li.dropdown-submenu ul.submenu.open {
        left: 74.85px;
    }
  
    .cl-sidebar .collapse-button{
        display:none;
    }
    .cl-sidebar .collapse-button .search{
        display:none;
    }
}

@media (min-width: 768px) {
    #main-sidebar.sb-compressed .cl-vnavigation li a i { margin:0; }
    #main-sidebar.sb-compressed .cl-vnavigation li a span { opacity:0; max-width:0; max-height:0; white-space: nowrap; }
    .body-content { height:100%;position:absolute; }
}

@media (max-width: 767px) {
	#cl-wrapper{margin-top:0;display:block;}
    #head-nav.navbar { padding: 0 30px !important; }
    #head-nav .nav-toggle { margin-right: 5px;}
    #head-nav .nav-user-container { margin-left: 5px !important; }
	#pcont, .sb-collapsed #pcont {
		margin-left: 0;
        display:block;
	}
	
	.cl-mcont{ padding: 15px;}
	
	/*nav bar*/
	.navbar {padding-right: 10px;}
	.navbar-fixed-top {position:relative;margin:0;}
	
	/*dropdown menu*/
    .dropdown.profile_menu .dropdown-menu{
        position: absolute;
        background-color: #fff;
        color: #000;
        margin-left: 10px;
    }
    .dropdown.profile_menu .dropdown-menu > li > a{
        color: #000;
    }
    .dropdown.profile_menu .dropdown-menu:before{
        background: url(../images/menu-tail-up.png) no-repeat ;
        top: -10px;
        content: "";
        left: 10px;
        display: inline-block;
        height: 43px;
        width: 17px;
        position: absolute;
    }
    .not-nav .dropdown-menu:before{
        background: url(../images/menu-tail-up.png) no-repeat ;
        top: -10px;
        content: "";
        right: 13px;
        left: auto;
        display: inline-block;
        height: 43px;
        width: 17px;
        position: absolute;
    }
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {color:#000;}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {background-color: @primary-color;}
	.navbar-default .navbar-nav .dropdown-submenu > a:after {	border-width:0;}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {color:#FFF;}
	.navbar-nav .dropdown-menu > li > a:hover,
    .navbar-nav .dropdown-menu > li > a:focus ,
    .navbar-nav .dropdown-submenu:hover > a { background-color: transparent; }
	.navbar-nav .dropdown-submenu ul{
		display:block;
		padding-left:20px;
	}
	.navbar-nav .dropdown-header {color:#FFF;}
	.navbar-nav .col-menu-2 > li  > ul > li a {color:#FFF;padding-left: 45px;}
	.navbar-nav .col-menu-3 > li  > ul  > li a {color:#FFF;padding-left: 45px;}
	.navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-nav > .active > a:focus,
    .navbar-nav > .active > a:active {background:transparent !important;color:#FFF !important;}
    .not-nav{
        display: inline-block;
        float: right;
	    text-align:center;
	    margin:0;
        padding: 0 10px !important;
    }
	.navbar-default .navbar-nav > li.button {
		float:none;
		display:inline-block;
		padding:10px 0;
		top: 100%;
	}
    .not-nav > li > .dropdown-menu{	text-align:left;}
    .navbar-default .not-nav > li.button > a{
        width: 36px;
    }
	.navbar-default .navbar-nav > li.button > a > i {
		font-size: 17px;
		vertical-align: middle;
	}
	.navbar-default .navbar-nav > li.button .dropdown-menu{
		 position: absolute;
		 background-color: #FFFFFF;
		 box-shadow:0 6px 12px rgba(0, 0, 0, 0.176);
		 border-top:0;
		 margin-left:-218px;
	}
	.navbar-default .navbar-nav > li.button .dropdown-menu > li > a{text-align:left;}
	.navbar-collapse {padding:0 !important;}
	.navbar-collapse .navbar-nav {margin:0;}
	.profile_menu {margin:0;}
	.profile_menu .dropdown-toggle {
        padding-left:15px;
        background-color: transparent !important;
        color: #000 !important;
      }
	.navbar-default .navbar-brand {width:auto;}
	.user-nav{
		margin: 7.5px -15px;
        display: inline-block;
	}
	.navbar-collapse.in {overflow:visible;}
    #head-nav.topbar .sidebar-logo{ display: none;}
    #head-nav.topbar .navbar-nav{padding: 5px 20px;}
    #head-nav.topbar .horizontal>li>a{padding: 10px 15px;}
    #head-nav.topbar .navbar-nav .dropdown .dropdown-menu{
        margin-top: 0;
        box-shadow:none;
        position: static;
    }
    #head-nav.topbar .user-nav{display:block;}
    #head-nav.topbar .user-nav > .open > a, #head-nav.topbar .user-nav > .open > a:hover, #head-nav.topbar .user-nav > .open > a:focus{
        background-color: @primary-color + 10% !important;
    }
    #head-nav.topbar .profile_menu .dropdown-toggle{ color: #fff !important; padding: 10px;}
    #head-nav.topbar .dropdown.profile_menu .dropdown-menu{background: transparent;}
    #head-nav.topbar .dropdown.profile_menu .dropdown-menu:before{ display:none;}
    #head-nav.topbar .navbar-nav .dropdown .dropdown-menu>li>a{color:#fff;}
    #head-nav.topbar .not-nav{
        background: @primary-color - 10%;
        display:block;
        float:none;
    }
	#head-nav.topbar .not-nav .dropdown .dropdown-menu{position:absolute;}
    #head-nav.topbar .not-nav .dropdown-menu:before{ right: 123px;}
    #head-nav.topbar .navbar-nav > li.button .dropdown-menu{ margin-left:-110px;}
	.col-menu-2 > div:first-child {border-right: 0;}
	/*end - dropdown*/
	
    .cl-sidebar, .sb-collapsed  .cl-sidebar, #main-sidebar.sb-compressed{
        background: none repeat scroll 0 0 #272930;
        width: 100%;
        position:relative;
        z-index: 1020;
        display:block;
        padding-bottom:0;
	}
    .sidebar-toggle { display: none; }
    #cl-wrapper.fixed-menu .menu-space, #cl-wrapper.fixed-menu.sb-collapsed .menu-space {position:relative;height:auto !important;width:100%;}
    #cl-wrapper .menu-space{min-height:0 !important;}
    #cl-wrapper.fixed-menu .menu-space .content, #cl-wrapper.fixed-menu.sb-collapsed .menu-space .content {overflow:visible;position:relative;}
    .sb-collapsed .cl-vnavigation li a span {opacity:1;}
    .sb-collapsed .cl-vnavigation li a i {
        font-size: 14px;
        margin-right: 7px;
        text-align: center;
        width: 20px;
    }
    #contact-btn {
        position: relative !important;
    }
    .cl-vnavigation ul.submenu {
        padding: 0 !important;
        margin: 0 !important;
    }
    .cl-vnavigation li ul.submenu a {
        padding-left: 65px !important;
    }
    .cl-vnavigation li ul.submenu.open,
    .cl-vnavigation li ul.submenu:not(.open),
    .sb-collapsed .cl-vnavigation li.open ul {
        display: block !important;
    }
    .cl-vnavigation ul.submenu,
    .cl-vnavigation ul.submenu li,
    .cl-vnavigation ul.submenu li a {
        display: block;
    }
    .cl-vnavigation li.dropdown-submenu ul.submenu.open {
        left: 0;
        position: relative;
    }
    .cl-sidebar .cl-toggle{display:block;}
    .cl-sidebar .cl-vnavigation li {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;
    }
    .cl-sidebar .cl-vnavigation li a {
        opacity: 0;
        transition: opacity 0.5s;
    }
    .cl-sidebar .cl-vnavigation{
	    /*display: none;*/
        /*height: 0;*/
        margin-top: 0;
        overflow: hidden;
    }
    .cl-sidebar .side-user{
        display:none;
    }
    .table-responsive{
        border:0;
    }
	.butstyle{
		width:100%;
		margin-right:10px;
		margin-left:0;
		max-width: 45%;
	}
	.form-horizontal.group-border .form-group{
		padding:5px 0 15px 0;
	}
	.form-horizontal.group-border .form-group label{margin-bottom:7px;}
	.form-horizontal.group-border-dashed .form-group label{margin-bottom:7px;}
	
	.login-container .middle-login{
        left:auto;
        width:100%;
        padding: 0 15px;
        margin-left:0;
	}
    .cl-sidebar .collapse-button{
        display:none;
    }
}
@media (max-width: 991px) {
    .single-address + .single-address {
        border-top: 1px solid #eee !important;
        margin-top: 15px !important;
        padding-top: 30px !important;
    }
}
@media (max-width: 767px) {
    .single-email + .single-email {
        border-top: 1px solid #eee !important;
        margin-top: 20px !important;
        padding-top: 10px !important;
    }
}

div[class^="col-fixed-"] { position: absolute; }

.col-fixed-40 { width: 40px; }
.col-fixed-60 { width: 60px; }
.col-fixed-70 { width: 70px; }

.col-offset-40 { padding-left: 55px; }
.col-offset-60 { padding-left: 75px; }
.col-offset-70 { padding-left: 85px; }

.col-xs-offset-right-12 { margin-right: 100%; }
.col-xs-offset-right-11 { margin-right: 91.66666667%; }
.col-xs-offset-right-10 { margin-right: 83.33333333%; }
.col-xs-offset-right-9 { margin-right: 75%; }
.col-xs-offset-right-8 { margin-right: 66.66666667%; }
.col-xs-offset-right-7 { margin-right: 58.33333333%; }
.col-xs-offset-right-6 { margin-right: 50%; }
.col-xs-offset-right-5 { margin-right: 41.66666667%; }
.col-xs-offset-right-4 { margin-right: 33.33333333%; }
.col-xs-offset-right-3 { margin-right: 25%; }
.col-xs-offset-right-2 { margin-right: 16.66666667%; }
.col-xs-offset-right-1 { margin-right: 8.33333333%; }
.col-xs-offset-right-0 { margin-right: 0; }

.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {text-align: left; }
.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg { text-align: center; }
.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg { text-align: right; }
.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg { text-align: justify; }

@media (max-width: 767px) {
    .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs { text-align: inherit; }
    .text-left-xs { text-align: left; }
    .text-center-xs { text-align: center; }
    .text-right-xs { text-align: right; }
    .text-justify-xs { text-align: justify; }
}
@media (min-width: 768px) {
    .col-sm-offset-right-12 { margin-right: 100%;}
    .col-sm-offset-right-11 { margin-right: 91.66666667%; }
    .col-sm-offset-right-10 { margin-right: 83.33333333%; }
    .col-sm-offset-right-9 { margin-right: 75%; }
    .col-sm-offset-right-8 { margin-right: 66.66666667%; }
    .col-sm-offset-right-7 { margin-right: 58.33333333%; }
    .col-sm-offset-right-6 { margin-right: 50%; }
    .col-sm-offset-right-5 { margin-right: 41.66666667%; }
    .col-sm-offset-right-4 { margin-right: 33.33333333%; }
    .col-sm-offset-right-3 { margin-right: 25%; }
    .col-sm-offset-right-2 { margin-right: 16.66666667%; }
    .col-sm-offset-right-1 { margin-right: 8.33333333%; }
    .col-sm-offset-right-0 { margin-right: 0; }
    
    .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm { text-align: inherit; }
    .text-left-sm { text-align: left; }
    .text-center-sm { text-align: center; }
    .text-right-sm { text-align: right; }
    .text-justify-sm { text-align: justify; }
}
@media (min-width: 992px) {
    .col-md-offset-right-12 { margin-right: 100%; }
    .col-md-offset-right-11 { margin-right: 91.66666667%; }
    .col-md-offset-right-10 { margin-right: 83.33333333%; }
    .col-md-offset-right-9 { margin-right: 75%; }
    .col-md-offset-right-8 { margin-right: 66.66666667%; }
    .col-md-offset-right-7 { margin-right: 58.33333333%; }
    .col-md-offset-right-6 { margin-right: 50%; }
    .col-md-offset-right-5 { margin-right: 41.66666667%; }
    .col-md-offset-right-4 { margin-right: 33.33333333%; }
    .col-md-offset-right-3 { margin-right: 25%; }
    .col-md-offset-right-2 { margin-right: 16.66666667%; }
    .col-md-offset-right-1 { margin-right: 8.33333333%; }
    .col-md-offset-right-0 { margin-right: 0; }

    .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md { text-align: inherit; }
    .text-left-md { text-align: left; }
    .text-center-md { text-align: center; }
    .text-right-md { text-align: right; }
    .text-justify-md { text-align: justify;}
}
@media (min-width: 1200px) and (max-width: 1199px) {
    .col-lg-offset-right-12 { margin-right: 100%; }
    .col-lg-offset-right-11 { margin-right: 91.66666667%; }
    .col-lg-offset-right-10 { margin-right: 83.33333333%; }
    .col-lg-offset-right-9 { margin-right: 75%; }
    .col-lg-offset-right-8 { margin-right: 66.66666667%; }
    .col-lg-offset-right-7 { margin-right: 58.33333333%; }
    .col-lg-offset-right-6 { margin-right: 50%; }
    .col-lg-offset-right-5 { margin-right: 41.66666667%; }
    .col-lg-offset-right-4 { margin-right: 33.33333333%; }
    .col-lg-offset-right-3 { margin-right: 25%; }
    .col-lg-offset-right-2 { margin-right: 16.66666667%; }
    .col-lg-offset-right-1 { margin-right: 8.33333333%; }
    .col-lg-offset-right-0 { margin-right: 0;}

    .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg { text-align: inherit; }
    .text-left-lg { text-align: left; }
    .text-center-lg { text-align: center; }
    .text-right-lg { text-align: right; }
    .text-justify-lg { text-align: justify; }
}