/*Pagination*/
.pagination {
	margin:18px 0;
}
.pagination > li > a, .pagination > li > span{
  color: @primary-color;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
	background-color: @primary-color;
  border-color: @primary-color;
}

.pagination > li:first-child > a, .pagination > li:first-child > span{
    border-radius:0;
}

.pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius:0;
}