
/* NAV THEME #3 - WHITE */
/*#head-nav.navbar, #head-nav.navbar button.btn, #head-nav.navbar .input-group-addon, #head-nav.navbar input,
#head-nav.navbar button.btn:hover, #head-nav.navbar .input-group-addon:hover { background-color: @theme-3-nav-bgcolor; }
#head-nav.navbar { position: relative; z-index: 10; box-shadow: 0px 0px 5px #555; }
.cl-sidebar .sidebar-logo { background-color: @theme-3-nav-bgcolor; height: 64px; padding-top: 15px; position: relative; z-index: 10; box-shadow: 0px 0px 5px #555; }
.cl-sidebar .logo { background: url(../images/ABET_RGB_SM.png) top center no-repeat; background-size: 36px 36px; }
#head-nav.navbar button.btn, #head-nav.navbar input, #head-nav.navbar .input-group-addon { border-color: @theme-3-accent-color; color:@theme-3-accent-color; }
#head-nav.navbar button.btn, #head-nav.navbar .input-group-addon { background: #F0F0F0; }
#head-nav.navbar i.fa, #head-nav.navbar ::-webkit-input-placeholder { color: @theme-3-accent-color; }*/

/* NAV THEME #2 - BLACK */
#head-nav.navbar { background-color: black; }
.sidebar-logo { background-color: black; height: 64px; padding-top: 15px; }
.logo { background: url(../images/ABET_WHITETXT_SM.png) top center no-repeat; background-size: 36px 36px; }
#head-nav.navbar button.btn, #head-nav.navbar input, #head-nav.navbar .input-group-addon { border-color: black; color: white; }
#head-nav.navbar .input-group i, #head-nav.navbar .btn i { color: white; }
#head-nav.navbar button.btn, #head-nav.navbar .input-group-addon { background: @abet-gray; }
#head-nav.navbar input { background: @medium-gray; }
#head-nav.navbar button.btn:hover, #head-nav.navbar .input-group-addon:hover { background: @abet-gray - 20%; color: #ddd; }
#head-nav.navbar ::-webkit-input-placeholder { color: @abet-gray; }
#head-nav.navbar input { color: black; }


/* GENERAL NAVIGATION */
#head-nav.navbar {
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
}
#head-nav * {
    margin-bottom: 0 !important;
}
#head-nav,
.nav-user-container {
    display: flex;
    max-width: 80rem;
}
.nav-searchbar-container {
    flex-grow: 1;
}
.nav-searchbar-container:not(.admin-view) {
    text-align: center;
}
.nav-user-container {
    margin-left: 15px !important;
}
.nav-user-container.btn-group ul.dropdown-menu {
    margin-left: 0;
    padding: 0;
    display: block;
}
.nav-user-container ul#user-dropdown li {
    max-height: 0;
    transition: max-height 0.6s ease-in-out;
}
.nav-user-container ul#user-dropdown li a {
    opacity: 0;
    transition: opacity 0.5s;
}
.nav-user-container ul#user-dropdown.open li {
    max-height: 500px;
}
.nav-user-container ul#user-dropdown.open li a {
    opacity: 1;
}
.nav-user-container ul.dropdown-menu i {
    margin-right: 10px;
}
.nav-user-container ul.dropdown-menu a {
    font-size: 14px !important;
}
.logo{
    height: 36px;
    text-indent: -9999999px;
}
input#omnisearch {
    border: 1px solid black;
    padding-left: 35px;
}
input.loading {
    background: url(http://samherbert.net/svg-loaders/svg-loaders/puff.svg) @medium-gray center left no-repeat;
    background-position-x: 5px;
    background-size: 25px 25px;
}
#head-nav.navbar .input-group-addon {
    border-left: none;
}
.navbar {
    border:0;
    background-color: #fff;
    color:#2c3e50;
    padding-left: 10px;
    padding-right: 25px;
    margin-bottom: 0;
}
.navbar-nav > li > a {
    padding: 22px;
}

.navbar-collapse {
    padding: 15px;
}
.navbar-default  .navbar-brand {
    padding-left: 35px;
	text-align:left;
	width: 206px;
	color: #2c3e50;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    font-weight: 300;
    font-size:19px;
    line-height:18px;
}
.navbar-default .navbar-brand:hover{
	color: #2c3e50 - 10%;
}
.navbar-default  .navbar-nav > .active > a, .navbar-default  .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus {
    color: #3F4148;
}

.navbar-default  .navbar-nav > li > a {
    color: #3F4148;
    font-size: 14px;
    font-weight: 400;
}
.navbar-default  .navbar-nav > li > a:hover {
	color:#2c3e50 - 10%;
}
.navbar-default  .navbar-nav > li.button  {
	padding: 17px 6px;
	float:left;
}

.navbar-default  .navbar-nav > li.button > a {
	border-radius: 2px;
	-webkit-border-radius: 2px;
	padding:4px;
	width:30px;
	box-shadow:1px 1px 0 rgba(255, 255, 255, 0.2) inset;
	text-align:center;
}

.navbar-default .not-nav > li.button{ padding:11px 0;}
.navbar-default .not-nav > li.button > a{ padding: 10px; width: 42px;}

.navbar-default  .navbar-nav > li.button > a > i {
	font-size: 18px;
}

.navbar-default  .navbar-nav > li.button .bubble{
	position: absolute;
	top: 2px;
	left: 23px;
	background-color: #e44c34;
	color: #fff;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	border-color:#E23923 #D83722 #C0311E;
	border-left:1px solid #D83722;
	border-width: 1px;
	box-shadow:-1px 1px 0 rgba(0, 0, 0, 0.25);
	padding: 1px 3px 1px 2px;
	font: bold 9px Tahoma;
}

.navbar-default .navbar-nav > li.button.open:after {
    position: absolute;
    bottom: 0;
    left: 10px;
    display: inline-block;
    content: '';
    left: 50%;
    margin-left: -6px;
}
.navbar-nav > .btn-default {
    font-family: @header-font;
    text-transform: uppercase;
}

 .navbar-nav > li.button .dropdown-menu{ 
	padding:0;
	min-width:260px;
	max-width: 280px;
 }

 .navbar-nav > li.button .dropdown-menu .content > ul{
    padding:0;
 }

.navbar-default .navbar-nav > li.button .dropdown-menu  li  ul > li > a{
	font-weight:300;
	color:#444;
	padding:11px 10px;
	padding-left: 33px;
	border-bottom:1px solid #ececec;
	position:relative;
}

.navbar-default .navbar-nav > li.button .messages  li  ul > li > a{
	padding:12px 10px;
	padding-left: 48px;
	white-space:normal;
	line-height:16px;
}

.navbar-default .navbar-nav > li.button .dropdown-menu  li > a:hover{color:#444;background:#F8F8F8;}
.navbar-default .navbar-nav > li.button .dropdown-menu  li:last-child > a{border-bottom:0;}

.navbar-default .navbar-nav > li.button .dropdown-menu  li > a > i {
    display: inline-block;
    font-size: 14px;
    text-align: center;
    width: 20px;
    vertical-align: middle;
    position:absolute;
    left:10px;
    top:14px;
}

.navbar-default .navbar-nav > li.button .messages  li > a > img{
	position:absolute;
	left:10px;
	top:16px;
	height:30px;
	width:30px;
}

.navbar-default .navbar-nav > li.button .messages  li > a .name{
	display:block;
	color: #555555;
    font-size: 12px;
    font-weight: 600;
	line-height:19px;
}

.navbar-default .navbar-nav > li.button .dropdown-menu  li > a > i.success {color: @dark-green;}
.navbar-default .navbar-nav > li.button .dropdown-menu  li > a > i.info {color: @blue;}
.navbar-default .navbar-nav > li.button .dropdown-menu  li > a > i.warning {color: @yellow;}
.navbar-default .navbar-nav > li.button .dropdown-menu  li > a > i.danger {color: @red;}
.navbar-default .navbar-nav > li.button .dropdown-menu .nano{height:200px;}

.navbar-default .navbar-nav > li.button .dropdown-menu  li  a  .date{
	display:block;
	font-size:11px;
	color: #999999;
	font-style:italic;
	line-height: 18px;
}

.navbar-default .navbar-nav > li.button .dropdown-menu .title {
	font-size:12px;
	font-weight:300;
	border-bottom:1px solid #DDDDDF;
	padding:8px 17px;
	color:#444;
}

.navbar-default .navbar-nav > li.button .dropdown-menu .title a:hover{background:transparent;}

.navbar-default .navbar-nav > li.button .dropdown-menu .foot {
  padding:0;
}
.navbar-default .navbar-nav > li.button .dropdown-menu .foot > li{list-style:none;}
.navbar-default .navbar-nav > li.button .dropdown-menu .foot a{
	background:#F7F7F7;
	padding:8px 17px;
	color:#444;
	text-align:center;
}

/*Dropdown Sub menu*/
.dropdown-submenu{position:relative;}
.dropdown-submenu>.dropdown-menu{top:0px;left:100%;margin-top:-6px;margin-left:-1px;-webkit-border-radius:0 0 3px 3px;-moz-border-radius:0 0 3px 3px;border-radius:0 0 3px 3px;}
.dropdown-submenu:hover>.dropdown-menu{display:block;}
.dropdown-submenu>a:after{display:block;content:" ";border-color:transparent;border-style:solid;border-width:5px 0 5px 5px;border-left-color:#cccccc;margin-left:10px;margin-right:-10px;}
.dropdown-submenu:hover>a:after{border-left-color:#ffffff;}
.dropdown-submenu.pull-left{float:none;}.dropdown-submenu.pull-left>.dropdown-menu{left:-100%;margin-left:10px;-webkit-border-radius:0 0 6px 6px;-moz-border-radius:0 0 6px 6px;border-radius:0 0 6px 6px;}


.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus ,
.dropdown-submenu:hover > a ,
.dropdown-menu  li > a:hover {
    background-color: #5f5d5d;
    background-image: none;
    color:#FFF;
}

.navbar-default .navbar-nav > .dropdown > a .caret {
    border-bottom-color: #000;
    border-top-color: #000;
}

.navbar-default .navbar-nav > .dropdown > a:hover .caret, .navbar-default .navbar-nav > .dropdown > a:focus .caret {  border-bottom-color: #000; border-top-color: #000;}


.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-nav > .open > a:focus {
    background-color: @lightedp-color;
    color: #2c3e50 - 10%;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background-color: @primary-color;
    color: #fff;
}

.not-nav > .open > a, .not-nav > .open > a:hover, .not-nav > .open > a:focus{
    background-color: transparent !important;
    color: @primary-color !important;
}

.navbar-default .navbar-toggle {
    outline:none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
    padding: 6px 10px;
}

.navbar-default .navbar-toggle:hover{
	background:rgba(255,255,255,0.07);
}
.navbar-default .navbar-toggle:active{box-shadow:1px 1px 1px rgba(0, 0, 0, 0.07) inset;}
.navbar-default .navbar-toggle:focus{background:transparent;}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #FFFFFF;
}

.navbar-default .navbar-toggle .fa {
   color: #FFFFFF;
   font-size:18px;
}

.navbar-collapse {
    border-top:none;
    max-height:none;
    position:relative;
}