/*Modals*/
.md-modal.full-width{
  width: 90%;
  max-width:none;
}
.modal-dialog.full-width{
  width: 90%;
  max-width:none;
}

.custom-width{
  width: 40%;
  max-width: none;
}

.full-color .md-content{background-color: @primary-color;color: #fff;}
.full-color .modal-content{background-color: @primary-color;color: #fff;}
.full-color.success .md-content{background-color: @dark-green;color: #fff;}
.full-color.success .modal-content{background-color: @dark-green;color: #fff;}
.full-color.info .md-content{background-color: @blue;color: #fff;}
.full-color.info .modal-content{background-color: @blue;color: #fff;}
.full-color.warning .md-content{background-color: @orange;color: #fff;}
.full-color.warning .modal-content{background-color: @orange;color: #fff;}
.full-color.danger .md-content{background-color: @red;color: #fff;}
.full-color.danger .modal-content{background-color: @red;color: #fff;}
.full-color.prusia .md-content{background-color: @prusia;color: #fff;}
.full-color.prusia .modal-content{background-color: @prusia;color: #fff;}
.full-color .modal-header{
  background-color: rgba(0,0,0,0.1);
}
.full-color .modal-content .modal-footer{
  border: 0;
 background-color: transparent;
}
.full-color .md-content .modal-footer{
  border: 0;
  background-color: transparent;
}
.full-color .md-content .modal-footer .btn{
  margin-bottom: 0 !important;
}
.md-dark .modal-header{background-color: @primary-color;color: #fff;}
.md-dark .modal-header{background-color: @primary-color;color: #fff;}
.md-dark.success .modal-header{background-color: @dark-green;color: #fff;}
.md-dark.success .modal-header{background-color: @dark-green;color: #fff;}
.md-dark.info .modal-header{background-color: @blue;color: #fff;}
.md-dark.info .modal-header{background-color: @blue;color: #fff;}
.md-dark.warning .modal-header{background-color: @orange;color: #fff;}
.md-dark.warning .modal-header{background-color: @orange;color: #fff;}
.md-dark.danger .modal-header{background-color: @red;color: #fff;}
.md-dark.danger .modal-header{background-color: @red;color: #fff;}
.md-dark.prusia .modal-header{background-color: @prusia;color: #fff;}
.md-dark.prusia .modal-header{background-color: @prusia;color: #fff;}
.md-dark .md-content, .md-dark .modal-content{
  background-color: #23262B;
  color: #BBBEC3;
}
.md-dark .md-content input, .md-dark .modal-content input{
  background-color: transparent;
  color: #b2b2b2;
}
.md-dark .modal-content .modal-footer{
  border: 0;
 background-color: transparent;
}
.md-dark .md-content .modal-footer{
  border: 0;
  background-color: transparent;
}
.md-dark .md-content .modal-footer .btn{
  margin-bottom: 0 !important;
}

.modal-content {
border-radius:5px;
-webkit-border-radius:5px;
border:0;
}

.modal-body {
    overflow-x: visible;
    overflow-y: auto;
    max-height: 75vh;
    padding: 30px;
    position: relative;
}
.modal-body.short {
    max-height: 55vh;
}
.overflow .modal-body {
    overflow-y: initial;
}
.modal-body.form{ padding:30px 20px 15px;}
.md-content .icheckbox_flat-green, .modal-content .icheckbox_flat-green{margin-right: 5px;}
.modal-content > .close {
	margin-right:10px;
	margin-top: 10px;
}

.modal-content .modal-header {
    display: flex;
	border-bottom:0;
}
.modal-content .modal-header > * {
    flex-grow: 1;
}
.colored-header .modal-header{
  padding: 10px 20px;
  border-bottom: 1px solid #e5e5e5 !important;
  background-color: #FBFBFB;
  color: @abet-gray;
}
.colored-header.success .modal-header{
  padding: 20px;
  border-bottom: 5px solid @dark-green - 20% !important;
  background-color: @dark-green;
  color: #fff;
}
.colored-header.info .modal-header{
  padding: 20px;
  border-bottom: 5px solid @blue - 20% !important;
  background-color: @blue;
  color: #fff;
}
.colored-header.warning .modal-header{
  padding: 20px;
  border-bottom: 5px solid @orange - 20% !important;
  background-color: @orange;
  color: #fff;
}
.colored-header.danger .modal-header{
  padding: 20px;
  border-bottom: 5px solid @red - 20% !important;
  background-color: @red;
  color: #fff;
}
.colored-header.prusia .modal-header{
  padding: 20px;
  border-bottom: 5px solid @prusia - 20% !important;
  background-color: @prusia;
  color: #fff;
}
.colored-header.dark .modal-header{
  padding: 20px;
  border-bottom: 5px solid @dark - 20% !important;
  background-color: @dark;
  color: #fff;
}

.modal-content h4{
	font-weight:400;
}

.modal-content h3{
    font-weight:300;
    margin-top:0;
    display: inline;
}

.modal-content h2{
  margin:0;
}

.modal-content .modal-footer{
    margin-top: 0;
	background:#FBFBFB;
	-webkit-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px;
}
 
@media (min-width: 768px) and (max-width: 979px) {
  .custom-width{
    width: 60%;
    max-width: none;
  }

}
@media (max-width: 767px) {
  .custom-width{
    width: 90%;
    max-width: none;
  }
}

.modal-footer hr {
    margin: 0 0 10px;
}

.modal-footer {
    color: #888;
    font-size: 14px;
}

.modal-footer.last-edit {
    padding: 10px 20px;
}

.modal-footer span.validation-key {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 3px 5px;
}

.modal-footer span.validation-key:not(:last-of-type) {
    margin-right: 10px;
}

.modal-footer button.btn {
    margin-bottom: 0 !important;
}

body.modal-open .popover {
    z-index: 999999;
}